import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { listAccountsAsync } from "./redux/slices/accountSlice";
import { firstAccountStatStatus } from "./redux/slices/dashboardSlice";
import { getCustomerId, getUserId } from "./utils/SessionHelper";
import Account from "./views/Account";
import CheckList from "./views/CheckList";
import DataStoreDetails from "./views/Datastore/datastore-details.view";
import DataStore from "./views/Datastore/datastore.view";
import Home from "./components/home/HomeV1";
import CompleteProfile from "./views/Login/complete-profile.view";
import ForgotPassword from "./views/Login/forgot-password.view";
import LoginCallback from "./views/Login/login-callback.view";
import Login from "./views/Login/login.view";
import Box from "./components/common/Box";
import ResetPassword from "./views/Login/reset-password.view";
import Timeout from "./views/Login/timeout.view";
import VerifyEmail from "./views/Login/verify-email.view";
import Register from "./views/Register/Register.view";
import Reports from "./views/Reports";
import Settings from "./views/Settings";
import Test from "./views/test";
import Tickets from "./views/Tickets";
import UserManagement from "./views/UserManagement";
import VulnerabilitiesDetails from "./views/Vulnerabilities/vulnerabilities-details.view";
import ShadowLLM from "./views/Vulnerabilities/shadow-llm.view";
import Vulnerabilities from "./views/Vulnerabilities/vulnerabilities.view";
import Identity from "./views/Identity";
import Assets from "./views/Assets";
// import Identity from "./views/Identity";
import PolicySetting from "./views/PolicySetting/PolicySetting.view";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/Sidebar";
import "./components/layout/Layout.scss";
import { getSelectedDatastoreAccount } from "./utils/SessionHelper";
import ReportDoc from "./components/reports/report";
import { useSelector } from "react-redux";
import EmbededUiModal from "./components/modal/embeded-ui/EmbededUiModal";
import Footer from "./components/footer";
import { useTheme } from "@mui/material";
import NewHeader from "./components/common/Header";
import Insights from "./components/common/Insights";
import { useContext } from "react";
import { AppContext } from "./context/appContext";
import InsightsDrawer from "./components/dashboard-widgets/InsightsDrawer";
import NewHome from "./components/home/NewHome";
import Doc from "./views/Docs/doc.view";

const App = () => {
  const theme = useTheme();
  const [loggendIn, setLoggendIn] = useState(false);
  const [path, setPath] = useState("/");
  const [settings, setSetting] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [ticket2, setTicket2] = useState(false);
  const [tab, setTab] = useState(1);
  const [infoModal, setInfoModal] = useState(false);
  const isIssueViewVisible = useSelector(
    (state) => state.dashboard.isIssueViewVisible
  );
  const expandedSidebar = useSelector((state) => state.dashboard.sidebar);
  const [accountAvailable, setAccountAvailable] = useState(false);

  const defaultAccount = getSelectedDatastoreAccount() || {
    value: "all",
    label: "All",
  };
  const [selectedAccount, setSelectedAccount] = useState(defaultAccount);

  const { openInsightsDrawer, setOpenInsightsDrawer } = useContext(AppContext);

  const dispatch = useDispatch();

  const checkAccounts = async () => {
    dispatch(
      firstAccountStatStatus({
        customerId: getCustomerId(),
      })
    );
    const resp = await dispatch(listAccountsAsync());
    if (resp && resp.payload) {
      if (resp?.payload?.length === 0) {
        setAccountAvailable(false);
      } else {
        setAccountAvailable(true);
      }
    }
  };
  useEffect(() => {
    let token = localStorage.getItem("authToken");
    if (token) {
      checkAccounts();
      setLoggendIn(true);
    }
  }, []);

  window.addEventListener("scroll", (event) => {
    const appHeader = document.querySelector(".appHeader");
    const top = window.scrollY;
    const offset = 30;
    if (appHeader) {
      if (top > offset) {
        appHeader.style.backgroundColor = theme.palette.surface0.main;
      } else {
        appHeader.style.backgroundColor = "transparent";
      }
    }
  });

  document.getElementsByTagName("html")[0].style.backgroundColor =
    theme.palette.surface0.main;

  const styles = {
    parent: {
      display: "flex",
      width: "100vw",
      backgroundImage: `radial-gradient(farthest-corner at 244px 0, #11162B 0%, #080B1E 58%)`,
    },
    contentParent: {
      display: "flex",
    },
    routesContainer: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      width: "100%",
    },
    contentPanel: {
      height: "auto",
      display: "flex",
      flexDirection: "column",
      marginLeft: loggendIn ? (expandedSidebar ? "180px" : "78px") : "0px",
      transition: "margin 0.4s ease-in-out",
      flexGrow: 1,
      width: "100%",
      overflowX: "clip",
    },
    headerContainer: {
      height: "auto",
      padding: theme.spacing(2),
      position: "sticky",
      top: 0,
      zIndex: 50,
      transition: "all 0.5s ease-in-out",
      backgroundColor: "transparent",
      width: "100%",
    },
    insightsBar: {
      marginLeft: "auto",
      width: "24px",
      display: "flex",
      justifyContent: "end",
      backgroundColor: "transparent",
    },
    widthFull: {
      width: "100%",
    },
  };

  return (
    <>
      <Router>
        <Switch>
          <Box sx={styles.parent}>
            <Box>
              {loggendIn ? <Sidebar expandedSidebar={expandedSidebar} /> : ""}
            </Box>
            <Box sx={styles.contentPanel}>
              {loggendIn &&
                (path === "/legacy-dashboard" ? (
                  <Header
                    selectedAccount={selectedAccount}
                    expandedSidebar={expandedSidebar}
                    setSelectedAccount={setSelectedAccount}
                    setInfoModal={setInfoModal}
                    isIssueViewVisible={isIssueViewVisible}
                  />
                ) : (
                  <Box sx={styles.headerContainer} className="appHeader">
                    <NewHeader
                      selectedAccount={selectedAccount}
                      expandedSidebar={expandedSidebar}
                      setSelectedAccount={setSelectedAccount}
                      setInfoModal={setInfoModal}
                      isIssueViewVisible={isIssueViewVisible}
                    />
                  </Box>
                ))}

              <Box sx={styles.routesContainer}>
                {loggendIn ? (
                  <Box sx={styles.contentParent}>
                    <Box sx={styles.widthFull}>
                      <Route exact path="/test" render={(props) => <Test />} />
                      <Route
                        exact
                        path="/on-boarding/:id"
                        render={(props) => (
                          <CompleteProfile setLoggendIn={setLoggendIn} />
                        )}
                      />
                      <Route
                        exact
                        path="/settings/admin"
                        render={(props) => <UserManagement setPath={setPath} />}
                      />
                      <Route
                        exact
                        path="/report"
                        render={(props) => <ReportDoc setPath={setPath} />}
                      />
                      <Route
                        exact
                        path="/reports"
                        render={(props) => <Reports setPath={setPath} />}
                      />
                      <Route
                        exact
                        path="/checklist"
                        render={(props) => (
                          <CheckList setSetting={setSetting} tab={tab} />
                        )}
                      />
                      <Route
                        exact
                        path="/tickets"
                        render={(props) => (
                          <Tickets setTicket2={setTicket2} tab={tab} />
                        )}
                      />
                      <Route
                        exact
                        path="/settings/integrations"
                        render={(props) => (
                          <Settings
                            setSetting={setSetting}
                            setPath={setPath}
                            tab={tab}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/settings/policy-settings"
                        render={(props) => <PolicySetting setPath={setPath} />}
                      />
                      <Route
                        exact
                        path="/settings/policy-settings/:policyID"
                        render={(props) => <PolicySetting setPath={setPath} />}
                      />
                      <Route
                        exact
                        path="/cloud/assets"
                        render={(props) => (
                          <Assets
                            setPath={setPath}
                            tab={tab}
                            path={path}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/cloud/identity"
                        render={(props) => (
                          <Identity
                            setPath={setPath}
                            tab={tab}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/account"
                        render={(props) => (
                          <Account setPath={setPath} tab={tab} />
                        )}
                      />
                      <Route
                        exact
                        path="/datastore"
                        render={(props) => (
                          <DataStore
                            setPath={setPath}
                            tab={tab}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/datastore/filter/:filterName"
                        render={(props) => (
                          <DataStore
                            setPath={setPath}
                            tab={tab}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/datastore/policy-violation/:policyViolationId"
                        render={(props) => (
                          <DataStore
                            setPath={setPath}
                            tab={tab}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/llm/:id/:assetId/:policyViolationId"
                        render={(props) => (
                          <ShadowLLM
                            selectedAccount={selectedAccount}
                            setPath={setPath}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/llm/:id/:assetId"
                        render={(props) => (
                          <ShadowLLM
                            selectedAccount={selectedAccount}
                            setPath={setPath}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/datastore-details/:id/:assetId"
                        render={(props) => (
                          <DataStoreDetails
                            setPath={setPath}
                            setTicket={setTicket}
                            tab={tab}
                          />
                        )}
                      />{" "}
                      <Route
                        exact
                        path="/datastore-details/:id/:assetId/:policyViolationId"
                        render={(props) => (
                          <DataStoreDetails
                            setPath={setPath}
                            setTicket={setTicket}
                            tab={tab}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/policy-details/:policyViolationId/:policyViolationInstanceId"
                        render={(props) => (
                          <DataStoreDetails
                            setPath={setPath}
                            setTicket={setTicket}
                            tab={tab}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/vulnerabilities"
                        render={(props) => (
                          <Vulnerabilities
                            setPath={setPath}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/vulnerabilities/cloud-env/:cloudEnvClass"
                        render={(props) => (
                          <Vulnerabilities
                            setPath={setPath}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/vulnerabilities/policy-violation/:policyViolationId"
                        render={(props) => (
                          <Vulnerabilities
                            setPath={setPath}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/vulnerabilities/:assetId"
                        render={(props) => (
                          <Vulnerabilities setPath={setPath} />
                        )}
                      />
                      <Route
                        exact
                        path="/vulnerabilities-details/:id/:assetId/:securityIssueInstanceId"
                        render={(props) => (
                          <VulnerabilitiesDetails setPath={setPath} />
                        )}
                      />
                      <Route
                        exact
                        path="/docs/wiz-integration"
                        render={(props) => (
                          <Doc
                            setPath={setPath}
                            selectedAccount={selectedAccount}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/docs/"
                        render={(props) => (
                          <Home
                            tab={tab}
                            setPath={setPath}
                            selectedAccount={selectedAccount}
                            infoModal={infoModal}
                            isIssueViewVisible={isIssueViewVisible}
                          />
                        )}
                      />
                      {/* <Route exact path="/admin" render={(props) => <Admin />} /> */}
                      <Route
                        exact
                        path="/"
                        render={(props) => (
                          <NewHome
                            tab={tab}
                            setPath={setPath}
                            selectedAccount={selectedAccount}
                            infoModal={infoModal}
                            isIssueViewVisible={isIssueViewVisible}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/legacy-dashboard"
                        render={(props) => (
                          <Home
                            tab={tab}
                            setPath={setPath}
                            selectedAccount={selectedAccount}
                            infoModal={infoModal}
                            isIssueViewVisible={isIssueViewVisible}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Route exact path="/test" render={(props) => <Test />} />
                    <Route
                      exact
                      path="/on-boarding/:id"
                      render={(props) => (
                        <CompleteProfile setLoggendIn={setLoggendIn} />
                      )}
                    />
                    <Route
                      exact
                      path="/reset-password"
                      render={(props) => <ResetPassword />}
                    />
                    <Route
                      exact
                      path="/forgot-password"
                      render={(props) => <ForgotPassword />}
                    />

                    <Route
                      exact
                      path="/timeout"
                      render={(props) => <Timeout />}
                    />

                    <Route
                      exact
                      path="/verify-email"
                      render={(props) => <VerifyEmail />}
                    />
                    <Route
                      exact
                      path="/register"
                      render={(props) => <Register />}
                    />
                    <Route
                      exact
                      path="/callback"
                      render={(props) => (
                        <LoginCallback setLoggendIn={setLoggendIn} />
                      )}
                    />
                    <Route
                      exact
                      path="/"
                      render={(props) => <Login setLoggendIn={setLoggendIn} />}
                    />
                  </>
                )}
              </Box>

              <Box>
                <Footer />
              </Box>
            </Box>
            {loggendIn && path === "/" && (
              <>
                <Box sx={styles.insightsBar}>
                  <Insights onClick={() => setOpenInsightsDrawer(true)} />
                </Box>
                <InsightsDrawer
                  open={openInsightsDrawer}
                  setOpenInsightsDrawer={setOpenInsightsDrawer}
                  anchor="right"
                />
              </>
            )}
          </Box>
        </Switch>
      </Router>

      {infoModal && <EmbededUiModal handleModal={setInfoModal} />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
