export const dataStoreTypes = [
  {
    label: "All",
    filter: "",
    value: "all",
  },
  {
    label: "Managed",
    filter: "managed",
    value: "managed",
  },
  {
    label: "AI",
    filter: "shadow",
    value: "ai",
  },
  {
    label: "Shadow",
    filter: "shadow",
    value: "shadow",
  },
];

export const datastoreFilter = [
  {
    filter: "",
    value: "all",
  },
  {
    filter: "MANAGED",
    value: "managed",
  },
  {
    filter: "SHADOW_AI",
    value: "ai",
  },
  {
    filter: "SHADOW",
    value: "shadow",
  },
];

export const non_SOC = [
  "data-residency",
  "encryption",
  "logging",
  "public-access",
];

export const SOCStatusFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Notified",
    value: "notified",
  },
  {
    label: "Archived",
    value: "archived",
  },
];

export const NonSOCStatusFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Ticketed",
    value: "ticketed",
  },
  {
    label: "Resolved",
    value: "resolved",
  },
];

export const socActionType = "notify/archive";
export const nonSocActionType = "ticket/accept";
