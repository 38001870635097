import { useTheme } from "@mui/material";
import { memo, useEffect } from "react";
import { useEdges } from "react-flow-renderer";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { CardWrapper, CardWrapperSmall } from "../CardWrapper.component";

const Card = ({
  title,
  curve,
  arrow,
  score,
  scoreClass,
  severity,
  selectedSeverity,
  filterIssues,
  seperator,
  label,
  disabled,
  length,
}) => {
  const theme = useTheme();
  const styles = {
    card: (disabled, selectedSeverity) => ({
      cursor: disabled ? "" : "pointer",
      width: "100%",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${
        selectedSeverity
          ? theme.palette.pictonBlue.main
          : theme.palette.surface10.main
      }`,
    }),
  };

  return (
    <>
      {length === 5 ? (
        <CardWrapperSmall>
          <Box
            sx={styles.card(disabled, selectedSeverity === severity)}
            onClick={disabled ? () => "" : () => filterIssues(severity)}
          >
            <div
              class={
                severity === selectedSeverity
                  ? "card-body text-primary card-body-small selected-card"
                  : "card-body text-primary card-body-small "
              }
            >
              <Typography color={theme.palette.surface70.main}>
                {label}
              </Typography>
              <h2
                class={
                  severity === selectedSeverity
                    ? `card-number ${scoreClass} risk-card-score selected-card-text`
                    : `card-number ${scoreClass} risk-card-score `
                }
              >
                {score}
              </h2>
            </div>
          </Box>
        </CardWrapperSmall>
      ) : (
        <CardWrapper>
          <Box
            sx={styles.card(disabled, selectedSeverity === severity)}
            onClick={disabled ? () => "" : () => filterIssues(title)}
          >
            <div class="card border-success header-risk-card">
              <div class="card-body text-primary card-body-small">
                <Typography color={theme.palette.surface70.main}>
                  {label}
                </Typography>
                <h2 class={`card-number ${scoreClass} risk-card-score`}>
                  {score}
                </h2>
                {/* <img alt="" src={curve} className="curve-line" /> */}
              </div>
            </div>
          </Box>
          {/* {seperator ? <span class="vertical-line"></span> : ""} */}
        </CardWrapper>
      )}
    </>
  );
};

export default memo(Card);
