import { useTheme } from "@mui/material";
import Bar from "../common/Bar";
import Box from "../common/Box";
import Loader from "../common/Loader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import NoSearchIcon from "../../assets/img/No_Search_illustration.svg";
import Tooltip from "../common/Tooltip";
import { getNumberAbbreviation } from "../../utils/commonUtils";

export interface Props {
  barColor: string;
  dataDistribution: Array<{
    dataType: string;
    numberOfRecords: number;
    totalValue: number;
    impacted: number;
  }>;
  isFetchingData: boolean;
}

const RecordsTooltip = ({ numberOfRecords, totalRecords }) => {
  const theme = useTheme();
  const styles = {
    tooltipContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(1),
      gap: theme.spacing(0.5),
    },
  };
  return (
    <Box sx={styles.tooltipContainer}>
      <Typography
        variant="caption"
        align="right"
        color={theme.palette.surface0.main}
      >
        {getNumberAbbreviation(numberOfRecords)}
      </Typography>
      <Typography
        variant="caption"
        color={theme.palette.surface40.main}
        align="right"
      >
        / {getNumberAbbreviation(totalRecords)}
      </Typography>
    </Box>
  );
};

const DataStoreTable = ({
  barColor,
  dataDistribution,
  isFetchingData,
}: Props) => {
  const theme = useTheme();
  const styles = {
    table: {
      height: "100%",
    },
    tableImpactValue: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    tableBody: {
      height: "100%",
      overflow: "auto",
      width: "100%",
    },
    tableBodyRow: {},
    barCell: {
      width: "35%",
    },
    noDataContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    noDataMsg: {
      fontSize: "13px",
      color: theme.palette.surface60.main,
    },
    donutTooltip: {
      tooltip: {
        sx: {
          bgcolor: theme.palette.surface80.main,
          "& .MuiTooltip-arrow": {
            color: theme.palette.surface80.main,
          },
        },
      },
    },
  };

  return (
    <TableContainer>
      <Table sx={styles.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Data Type</TableCell>
            <TableCell sx={styles.barCell}>Number of Records</TableCell>
            <TableCell align="right">Impacted Data Stores</TableCell>
          </TableRow>
        </TableHead>
        {isFetchingData ? (
          <TableRow>
            <TableCell colSpan={3}>
              <Loader height="150px" />
            </TableCell>
          </TableRow>
        ) : (
          <TableBody sx={styles.tableBody}>
            {dataDistribution.length > 0 ? (
              dataDistribution?.map((tableRow, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {tableRow?.dataType}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      placement="bottom"
                      arrow
                      title={
                        <RecordsTooltip
                          numberOfRecords={tableRow.numberOfRecords}
                          totalRecords={tableRow.totalValue}
                        />
                      }
                      componentsProps={styles.donutTooltip}
                    >
                      <div>
                        <Bar
                          barFaceColor={barColor}
                          barBackColor={theme.palette.surface20.main}
                          rectWidth={
                            (tableRow.numberOfRecords / tableRow.totalValue) *
                            100
                          }
                          xLabel="Number of Items"
                          totalValue={tableRow.totalValue}
                          barLabel="Label"
                          index={1}
                          isLastBar={false}
                          barValue={tableRow.numberOfRecords}
                        />
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right" sx={styles.tableImpactValue}>
                    <Typography
                      variant="caption"
                      color={theme.palette.primaryBlue.main}
                      sx={styles.tableImpactValue}
                    >
                      {tableRow.impacted}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow sx={styles.table}>
                <TableCell colSpan={3}>
                  <Box sx={styles.noDataContainer}>
                    <img src={NoSearchIcon} alt="no data" />
                    <Typography sx={styles.noDataMsg}>
                      No records found
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default DataStoreTable;
