import React from "react";
import halfCircleLoader from "../../../assets/img/half-circle-loader.svg";
import { ReactComponent as CheckCircle } from "../../../assets/img/icons/check-circle.svg";
import DonutChart from "../../common/DonutChart";
const FirstAccountStatsSteps = ({
  title,
  score,
  scoreClass,
  index,
  cardDisable,
  stepCompleted,
  id,
  totalValue,
  donutData,
  defaultColor,
  variant,
  innerRadius,
  outerRadius,
  boxSize,
  donutHeight,
  donutWidth,
}) => {
  return (
    <>
      <div
        className={`
          ${
            !cardDisable && id === "highRiskStore" ? "low-opacity" : ""
          } pr-2 d-flex w-100`}
        key={`first_account_stat_${index}`}
      >
        <div
          class={`card iq-mb-2 card-disable border-success header-risk-card`}
        >
          <div className="card-body text-primary card-body-small">
            <h6 className="card-title risk-card-title mb-1 text-center">
              {title}
              {stepCompleted ? (
                <CheckCircle className="stats-icon" fill="#2195FF" />
              ) : (
                <img src={halfCircleLoader} className="stats-loading-icon" />
              )}
            </h6>
            <DonutChart
              donutData={donutData}
              totalValue={totalValue}
              defaultColor={defaultColor}
              variant={variant}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              boxSize={boxSize}
              donutHeight={donutHeight}
              donutWidth={donutWidth}
            />
            <h2 className={`card-number text-center  risk-card-score`}>
              {score}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstAccountStatsSteps;
