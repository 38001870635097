import { useState, useEffect } from "react";
import AddPolicyModal from "../modal/add-policy-modal/AddPolicyModal";
import {
  listPoliciesAsync,
  deletePolicyAsync,
} from "../../redux/slices/policySlice";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG } from "../../utils/Constants";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import DeleteConfirmationModal from "../modal/shared/DeleteConfirmationModal";
import CompliancePolicyModal from "../modal/compliance-policy-modal/CompliancePolicyModal";
import { getAllPolicies } from "../../redux/slices/policySlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { listDataStoreNames } from "../../redux/slices/dataStoreSlice";
import { useParams } from "react-router-dom";
import Button from "../common/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import { useTheme } from "@mui/material";
import Box from "../common/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "../common/Typography";

const PolicySetting = ({}) => {
  const [isAddPolicyModal, setIsAddPolicyModal] = useState();
  const [isAddCompliancePolicyModal, setIsAddCompliancePolicyModal] = useState(
    false
  );
  const params = useParams();
  const [policyAction, setPolicyAction] = useState();
  const [compliancePolicyAction, setCompliancePolicyAction] = useState();
  const [alertingPolicies, setAlertingPolicies] = useState([]);
  const [compliancePolicies, setCompliancePolicies] = useState([]);
  const dispatch = useDispatch();
  const [selectedPolicy, setSelectedPolicy] = useState();
  const policies = useSelector((state) => state.policy.policies);
  const [loading, setLoading] = useState();
  const [isDelete, setIsDelete] = useState();
  const [isComplianceDelete, setIsComplianceDelete] = useState();
  const [policiesList, setPoliciesList] = useState([]);
  const [datastoreMappings, setDatastoreMappings] = useState({});
  const [selectedAccountIDs, setSelectedAccountIDs] = useState();
  const selectedPolicyItem = sessionStorage.getItem("policy");
  const theme = useTheme();

  const styles = {
    tableParent: {
      height: "auto",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      marginY: theme.spacing(2),
    },
    sortIcon: {
      fontSize: theme.typography.body2,
      color: theme.palette.surface50.main,
    },
    actionsContainer: { width: "100px" },
    actionIcons: {
      color: theme.palette.primaryBlue.main,
      marginLeft: theme.spacing(1),
    },
    tableCellCenterAlign: {
      "&.MuiTableCell-root": {
        textAlign: "center",
      },
    },
  };

  const getNames = (name) => {
    return datastoreMappings[name];
  };

  const getDatastoreNames = async (ids) => {
    let payload = {
      customerId: getCustomerId(),
      datastores: ids,
    };
    let res = await dispatch(listDataStoreNames(payload));
    if (res && res.payload) {
      setDatastoreMappings(res.payload);
    }
  };

  useEffect(() => {
    if (!compliancePolicies?.length) return;
    let ids = [];
    for (let i of compliancePolicies) {
      for (let j of i?.target?.datastoreIds) {
        ids.push(j);
      }
    }
    getDatastoreNames(ids);
  }, [compliancePolicies]);

  const refreshAccounts = async () => {
    // await dispatch(listPoliciesAsync());
    getAllPolicieList();
  };
  const [showLoader, setShowLoader] = useState(false);

  const getAllPolicieList = async () => {
    setLoading(true);

    let res = await dispatch(listPoliciesAsync());
    if (res && res.payload && !res.payload.err) {
      if (res.payload && res.payload.length > 0) {
        let updatedCompliancePolicy = [];
        let updatedAlertingPolicy = [];
        res.payload.map((policy) => {
          if (policy.category == "alerting") {
            updatedAlertingPolicy.push(policy);
          }
          if (policy.category == "compliance") {
            updatedCompliancePolicy.push(policy);
          }
        });
        setAlertingPolicies(updatedAlertingPolicy);
        if (params?.policyID) {
          setCompliancePolicies(
            filterCompliancePolicies(updatedCompliancePolicy)
          );
        } else {
          setCompliancePolicies(updatedCompliancePolicy);
        }
      }
      // setAlertingPolicies(res.payload);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const filterCompliancePolicies = (updatedPolicies) => {
    let filteredPolicies = [];
    let policyId = params?.policyID;
    filteredPolicies = updatedPolicies.filter(
      (policyItem) => policyItem.policyId === policyId
    );
    return filteredPolicies;
  };
  const deletePolicy = async () => {
    setShowLoader(true);
    const resp = await dispatch(deletePolicyAsync(selectedPolicy._id));
    if (resp && resp.payload) {
      setShowLoader(false);
      setIsDelete(false);
      setIsComplianceDelete(false);

      Toaster(TOASTER_TYPES.SUCCESS, "Policy deleted successfully.");
      refreshAccounts();
    } else {
      setIsDelete(false);
      setIsComplianceDelete(false);

      setShowLoader(false);
      Toaster(TOASTER_TYPES.ERROR, "Failed to delete policy.");
    }
  };

  const refreshPolicyDetails = () => {
    getAllPolicieList();
  };

  useEffect(() => {
    getAllPolicieList();
  }, []);

  const getTriggerConditionValue = (value, items) => {
    if (items) {
      let actions = Object.keys(items);
      if (actions.includes(value) === true) {
        return <i class="ri-checkbox-circle-fill"></i>;
      } else {
        return <i class="ri-close-circle-fill"></i>;
      }
    } else {
      return <i class="ri-close-circle-fill"></i>;
    }
  };

  const getAlertingAction = (items) => {
    if (items) {
      let actions = Object.keys(items);
      if (actions.length > 0) {
        let action = actions[0];
        return items[action];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (selectedAccountIDs) {
      getPredefinesPolicieList();
    }
  }, [selectedAccountIDs]);

  const getPredefinesPolicieList = async () => {
    setLoading(true);
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId: selectedAccountIDs ? selectedAccountIDs : "",
    };
    let res = await dispatch(getAllPolicies(payload));
    let updatedPolicies = [];
    if (res && res.payload && !res.payload.err) {
      res.payload.map((policy) => {
        updatedPolicies.push({
          value: policy.policyId,
          label: policy.description,
        });
      });
      setPoliciesList(updatedPolicies);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPredefinesPolicieList();
  }, []);

  return (
    <>
      <div className="policy-settings ml-0">
        <div className="row m-0 justify-content-between align-items-center heading-section">
          <div className="col col-auto p-0 pb-2">
            <Typography color={theme.palette.surface100.main} variant="body2">
              Policy Settings
            </Typography>
          </div>
        </div>
        <BlockUi
          tag="div"
          blocking={loading}
          loader={
            <Loader
              active
              type={LOADER_CONFIG.type}
              color={LOADER_CONFIG.color}
            />
          }
        >
          <div className="row m-0 justify-content-between align-items-center heading-section">
            <div className="col col-auto py-2 pl-0">
              <Typography variant="body2">Alerting Policy</Typography>
            </div>
            <div className="col col-auto align-items-center p-2 ">
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  setIsAddPolicyModal(true);
                  setPolicyAction("add");
                }}
              >
                {" "}
                <img
                  alt=""
                  src="static/img/add-account.svg"
                  className="add-account-svg"
                />
                Add Alerting Policy
              </Button>

              {isAddPolicyModal && policyAction === "add" && (
                <AddPolicyModal
                  refreshPolicyDetails={refreshPolicyDetails}
                  refreshAccounts={refreshAccounts}
                  policyAction={policyAction}
                  handleModal={() => setIsAddPolicyModal(false)}
                  policiesList={policiesList}
                />
              )}
            </div>
          </div>
          <Box sx={styles.tableParent}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Policy Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Account</TableCell>
                    <TableCell sx={styles.tableCellCenterAlign}>
                      Risk Upgrade to Critical
                    </TableCell>
                    <TableCell sx={styles.tableCellCenterAlign}>
                      Risk Upgrade To High
                    </TableCell>
                    <TableCell sx={styles.tableCellCenterAlign}>
                      Direct Anomaly On Datastore
                    </TableCell>
                    <TableCell sx={styles.tableCellCenterAlign}>
                      Indirect Anomaly Via Cloud Environment
                    </TableCell>
                    <TableCell>Alerting Action</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alertingPolicies &&
                    alertingPolicies.map(function (item, idx) {
                      return (
                        <TableRow>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.description}</TableCell>
                          <TableCell>
                            <div className="row chip-container m-0">
                              {item.cloudAccountIds &&
                                item.cloudAccountIds.map((tag, i) => (
                                  <div className="workday-subheading" key={i}>
                                    <div className="chip" title={tag}>
                                      {tag}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </TableCell>
                          <TableCell sx={styles.tableCellCenterAlign}>
                            {getTriggerConditionValue(
                              "datastore_risk_critical",
                              item.triggerAction
                            )}
                          </TableCell>
                          <TableCell sx={styles.tableCellCenterAlign}>
                            {getTriggerConditionValue(
                              "datastore_risk_high",
                              item.triggerAction
                            )}
                          </TableCell>
                          <TableCell sx={styles.tableCellCenterAlign}>
                            {getTriggerConditionValue(
                              "direct_anomaly",
                              item.triggerAction
                            )}
                          </TableCell>
                          <TableCell sx={styles.tableCellCenterAlign}>
                            {getTriggerConditionValue(
                              "indirect_anomaly",
                              item.triggerAction
                            )}
                          </TableCell>
                          <TableCell sx={styles.tableCellCenterAlign}>
                            {getAlertingAction(item.triggerAction)}
                          </TableCell>
                          <TableCell
                            sx={{
                              ...styles.actionsContainer,
                              ...styles.tableCell,
                            }}
                          >
                            <EditIcon
                              sx={styles.actionIcons}
                              fontSize="small"
                              title="Edit Policy"
                              onClick={() => {
                                setIsAddPolicyModal(true);
                                setPolicyAction("edit");
                                setSelectedPolicy(item);
                              }}
                            />
                            <DeleteIcon
                              sx={styles.actionIcons}
                              fontSize="small"
                              title="Delete Policy"
                              onClick={() => {
                                setIsDelete(true);
                                setSelectedPolicy(item);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {isAddPolicyModal && policyAction === "edit" && (
                    <AddPolicyModal
                      refreshAccounts={refreshAccounts}
                      policyAction={policyAction}
                      policyItem={selectedPolicy}
                      handleModal={() => setIsAddPolicyModal(false)}
                      policiesList={policiesList}
                    />
                  )}
                  {isDelete && (
                    <DeleteConfirmationModal
                      handleModal={() => setIsDelete(false)}
                      deleteHandle={deletePolicy}
                      showLoader={showLoader}
                      modalData={{
                        title: "Delete Policy",
                        message:
                          "Are you sure, you want to delete this policy?",
                      }}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <div className="row m-0 justify-content-between align-items-center heading-section">
            <div className="col col-auto p-2">
              <Typography variant="body2">Compliance Policy</Typography>
            </div>
            <div className="col col-auto align-items-center p-2 ">
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  setIsAddCompliancePolicyModal(true);
                  setCompliancePolicyAction("add");
                }}
              >
                <img
                  alt=""
                  src="static/img/add-account.svg"
                  className="add-account-svg"
                />
                Add Compliance Policy
              </Button>

              {isAddPolicyModal && policyAction === "add" && (
                <AddPolicyModal
                  refreshAccounts={refreshAccounts}
                  policyAction={policyAction}
                  handleModal={() => setIsAddPolicyModal(false)}
                  policiesList={policiesList}
                />
              )}
            </div>
          </div>
          <Box sx={styles.tableParent}>
            <TableContainer sx={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Policy</TableCell>
                    <TableCell sx={styles.tableCellCenterAlign}>
                      Account
                    </TableCell>
                    <TableCell sx={styles.tableCellCenterAlign}>
                      Data Store
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compliancePolicies &&
                    compliancePolicies.map((policy) => {
                      return (
                        <TableRow>
                          <TableCell>{policy.policy}</TableCell>
                          <TableCell>
                            <div className="row chip-container m-0">
                              {policy?.target?.cloudAccountIds &&
                                policy?.target?.cloudAccountIds.map(
                                  (tag, i) => (
                                    <div
                                      className="workday-subheading m-auto"
                                      key={i}
                                    >
                                      <div className="chip" title={tag}>
                                        {tag}
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="row chip-container m-0">
                              {policy?.target?.datastoreIds &&
                                policy?.target?.datastoreIds.map((tag, i) => (
                                  <div
                                    className="workday-subheading m-auto"
                                    key={i}
                                  >
                                    <div className="chip" title={tag}>
                                      {getNames(tag)}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </TableCell>
                          <TableCell>
                            <EditIcon
                              sx={styles.actionIcons}
                              title="Edit Policy"
                              fontSize="small"
                              onClick={() => {
                                setIsAddCompliancePolicyModal(true);
                                setCompliancePolicyAction("edit");
                                setSelectedPolicy(policy);
                              }}
                            />
                            <DeleteIcon
                              sx={styles.actionIcons}
                              title="Delete Policy"
                              fontSize="small"
                              onClick={() => {
                                setIsComplianceDelete(true);
                                setSelectedPolicy(policy);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {isAddCompliancePolicyModal && (
                    <CompliancePolicyModal
                      setSelectedAccountIDs={setSelectedAccountIDs}
                      refreshAccounts={refreshAccounts}
                      policyAction={compliancePolicyAction}
                      policyItem={selectedPolicy}
                      handleModal={() => setIsAddCompliancePolicyModal(false)}
                      policiesList={policiesList}
                      setDatastoreMappings={setDatastoreMappings}
                    />
                  )}
                  {isComplianceDelete && (
                    <DeleteConfirmationModal
                      handleModal={() => setIsComplianceDelete(false)}
                      deleteHandle={deletePolicy}
                      showLoader={showLoader}
                      modalData={{
                        title: "Delete Policy",
                        message:
                          "Are you sure, you want to delete this policy?",
                      }}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </BlockUi>
        {/* <div className="mx-4 mt-4">
          <div>
            <b className="automation-policy-title">
              
              Custom Automation Policies
            </b>
          </div>
          <hr />
          <div className="mt-3">
            <table className="automation-policy-table">
              <thead>
                <tr>
                  <th>Policy Name</th>
                  <th>Description</th>
                  <th>Account</th>
                  <th>Issues</th>
                  <th>Resources</th>

                  <th>Alerting Action</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {automationPolicies.map(function (item, idx) {
                  return (
                    <tr key={idx}>
                      <td>{item.policyName}</td>
                      <td>{item.description}</td>
                      <td>{item.accounts}</td>
                      <td>{item.issues}</td>
                      <td>{item.resources}</td>
                      <td>{item.alertingAction}</td>
                      <td className="action-buttons">
                        <i
                          className="ri-pencil-fill mr-2 cursor-pointer"
                          title="Edit Policy"
                        ></i>
                        <i
                          className="ri-delete-bin-2-fill"
                          title="Delete Policy"
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default PolicySetting;
