import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Loader from "../common/Loader";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import DataStoreTable from "./DataStoreTable";

export interface Props {
  barColor: string;
  activeRegion: { data: any; region: string; regionCode: string };
  impactedDS: number;
  totalDS: number;
  dataDistribution: Array<{
    dataType: string;
    numberOfRecords: number;
    totalValue: number;
    impacted: number;
  }>;
  isFetchingData: boolean;
}

const GeoDataStoreCard = ({
  barColor,
  activeRegion,
  impactedDS,
  totalDS,
  dataDistribution,
  isFetchingData,
}: Props) => {
  const theme = useTheme();
  const styles = {
    cardContainer: {
      display: "flex",
      width: "100%",
      height: "450px",
      flexDirection: "column",
      gap: theme.spacing(3),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      boxShadow: `0px 0px 10px 0px ${theme.palette.surface0.main}, 0px 2px 3px -2px rgba(9, 12, 31, 0.70)`,
    },
    cardHeader: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    cardHeaderText: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    regionsText: {
      fontWeight: theme.typography.fontWeightLight,
    },
    moreDetailsButton: {
      display: "flex",
      marginLeft: "auto",
    },
    infoContent: {
      paddingX: theme.spacing(2),
    },
    impactedDataText: {
      fontFamily: "Manrope",
      fontSize: "28px",
    },
  };

  const getViewDetailsLink = () => {
    return activeRegion.regionCode === "all"
      ? "/datastore"
      : `/datastore?region=${activeRegion?.regionCode}`;
  };

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.cardHeader}>
        <Box sx={styles.cardHeaderText}>
          <Typography variant="caption" color={theme.palette.surface80.main}>
            Data Stores Distribution By Sensitive Data
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.surface80.main}
            sx={styles.regionsText}
          >
            {activeRegion.region}
          </Typography>
        </Box>
        <TextButton
          hideText={true}
          endIcon={<KeyboardArrowRightIcon />}
          sx={styles.moreDetailsButton}
          link={getViewDetailsLink()}
        >
          View All
        </TextButton>
      </Box>
      <Box sx={styles.infoContent}>
        <Typography
          sx={styles.impactedDataText}
          color={theme.palette.surface70.main}
        >
          {impactedDS}/{totalDS}
        </Typography>
        <Typography variant="caption" color={theme.palette.surface70.main}>
          All Impacted Data Stores
        </Typography>
      </Box>
      <DataStoreTable
        barColor={barColor}
        dataDistribution={dataDistribution}
        isFetchingData={isFetchingData}
      />
    </Box>
  );
};

export default GeoDataStoreCard;
