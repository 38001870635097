import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import "./AddAccountModal.scss";
import InfoModal from "../info-modal/InfoModal";
import infoFilled from "../../../assets/img/icons/ep-info-filled.svg";
import Button from "../../common/Button";

const AccountDetailStep = ({
  accountDetails,
  externalId,
  backStep,
  addAccount,
  handleStepOneModal,
  loading,
}) => {
  const dispatch = useDispatch();

  const DATA_SCANNING_FREQUENCY = [
    { label: "Daily", value: "rate(1 day)" },
    { label: "Weekly", value: "rate(7 days)" },
    { label: "Monthly", value: "rate(30 days)" },
  ];

  const [enableBtn, setEnableBtn] = useState(false);
  const [lunchStackModal, setLunchStackModal] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("us-east-1");
  const [dataScanFrequency, setDataScanFrequency] = useState(
    DATA_SCANNING_FREQUENCY[2].value
  );

  // Formik
  const formik = useFormik({
    initialValues: {
      accountId: accountDetails.accountId,
      accountDescription: accountDetails.accountDescription,
      externalId: externalId,
      awsRoleARN: accountDetails.awsRoleARN,
      dataScanFrequency: dataScanFrequency,
    },

    validationSchema: Yup.object().shape({
      accountId: Yup.string()
        .max(12, "Account Id should be 12 digit")
        .min(12, "Account Id should be 12 digit")
        .matches("^[0-9]", "Account Id should be numeric.")
        .required("Please enter valid Account Id"),
      externalId: Yup.string().required("Please enter External Id."),
      // awsRoleARN: Yup.string().required("Please enter Role Arn."),
    }),
    onSubmit: (values) => {
      addAccount(values);
      // nextStep(false);
    },
  });

  useEffect(() => {
    setEnableBtn(accountDetails.accountId && accountDetails.externalId);
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-body">
          {loading && (
            <img
              src="static/img/loader-circle.svg"
              className="modal-loading-img"
            ></img>
          )}
          <label className="label">Account Id</label>
          <span className="mandatory-icon">*</span>
          <div className="row">
            <div className="col col-lg-12">
              <input
                type="number"
                id="accountId"
                placeholder="12-Digit AWS Account Id"
                name="accountId"
                maxLength={"12"}
                minLength={"12"}
                className="form-control"
                onWheel={(event) => {
                  event.target.blur();
                }}
                onInput={(event) => {
                  if (!event.target.value.length) {
                    document.getElementById("accountId").value = "";
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event.keyCode === 38 ||
                    event.keyCode === 40 ||
                    event.keyCode === 69
                  ) {
                    event.preventDefault();
                    return;
                  }
                }}
                onChange={(e) => {
                  if (e.target.value.length === 13) {
                    return;
                  }
                  if (e.target.value === "e") {
                    return;
                  }
                  formik.setFieldValue("accountId", e.target.value?.trim());
                  setEnableBtn(true);
                }}
                value={formik.values.accountId}
                onBlur={formik.handleBlur}
              />
              {formik.errors.accountId && (
                <div className="error-message">{formik.errors.accountId}</div>
              )}
            </div>
          </div>
          <label className="label">Account Description</label>
          {/* <span className="mandatory-icon">*</span> */}
          <div className="row mt-2">
            <div className="col col-lg-12">
              <textarea
                className="form-control"
                placeholder="Account Description"
                name="accountDescription"
                id="accountDescription"
                onChange={(e) => {
                  formik.setFieldValue("accountDescription", e.target.value);
                }}
                value={formik.values.accountDescription}
              ></textarea>
            </div>
          </div>
          <label className="label">External ID</label>
          <span className="mandatory-icon">*</span>
          <div className="row">
            <div className="col col-lg-12">
              <input
                type="text"
                id="externalId"
                placeholder="External Id"
                name="externalId"
                className="form-control"
                onChange={(e) => {
                  formik.setFieldValue("externalId", e.target.value?.trim());
                  setEnableBtn(true);
                }}
                value={formik.values.externalId}
                onBlur={formik.handleBlur}
              />
              {formik.errors.externalId && formik.touched.externalId && (
                <div className="error-message">{formik.errors.externalId}</div>
              )}
            </div>
            <div className="col-lg-12">
              <label className="label">
                The External ID has been auto generated for you. You can change
                the ID to a custom ID.
              </label>
            </div>
          </div>
          <label className="label">Region</label>
          <div className="row">
            <div className="col col-lg-12">
              <div className="row">
                <div className="col col-lg-12">
                  <select
                    className="form-control"
                    value={selectedRegion}
                    onChange={(value) => {
                      setSelectedRegion(value.value);
                    }}
                  >
                    <option disabled>Select Region</option>
                    <option value={"us-east-1"}>us-east-1</option>
                    {/* {regionList.map((region, index) => (
                      <option key={index} value={region.value}>
                        {region.value}
                      </option>
                    ))} */}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <label className="label mt-2">Data Scanning Frequency</label>
          <div className="row">
            <div className="col col-lg-12">
              <div className="row">
                <div className="col col-lg-12">
                  <select
                    className="form-control"
                    value={formik.values.dataScanFrequency}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      setDataScanFrequency(event.target.value);
                      formik.setFieldValue(
                        "dataScanFrequency",
                        event.target.value
                      );
                    }}
                  >
                    <option disabled>Select Data Scanning Frequency</option>
                    {DATA_SCANNING_FREQUENCY.map((frequency, index) => (
                      <option key={index} value={frequency.value}>
                        {frequency.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="col p-0">
              <div className="float-left">
                <Button
                  onClick={() => {
                    backStep(false);
                  }}
                  variant="contained"
                  type="submit"
                  disabled={loading}
                >
                  Back: Select Account
                </Button>
              </div>
              <div className="float-right">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid || !enableBtn}
                >
                  Onboard Account
                </Button>
                <span className="separator mx-3"></span>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    handleStepOneModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {lunchStackModal ? (
        <InfoModal handleModal={() => setLunchStackModal(false)} />
      ) : (
        ""
      )}
    </div>
  );
};

export default AccountDetailStep;
