import "./UpdateAccountModal.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { updateStackAsync } from "../../../redux/slices/accountSlice";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import {
  showLoading,
  hideLoading,
} from "../../../redux/slices/globalOperationSlice";
import { LOADER_CONFIG } from "../../../utils/Constants";
import Button from "../../common/Button";

// Functional component for Add Account.
const UpdateAccountModal = ({
  handleModal,
  refreshAccounts,
  account,
  setEditDisabled,
  updateLaunchStack,
}) => {
  const dispatch = useDispatch();
  const [stackId, setStackId] = useState(null);

  const DATA_SCANNING_FREQUENCY = [
    { label: "Daily", value: "rate(1 day)" },
    { label: "Weekly", value: "rate(7 days)" },
    { label: "Monthly", value: "rate(30 days)" },
  ];

  const [dataScanFrequency, setDataScanFrequency] = useState();
  const loading = useSelector((state) => state.globalOperation.loading);

  const updateStack = async () => {
    dispatch(showLoading());
    const resp = await dispatch(
      updateStackAsync({
        id: account.id,
        stackId: stackId,
        dataScanFrequency: dataScanFrequency,
      })
    );
    dispatch(hideLoading());
    if (resp?.payload?.update && !resp?.payload?.err) {
      setEditDisabled(true);
      window.open(resp.payload?.update);
      refreshAccounts(); // TODO: Refresh accounts page if need be.
      handleModal(false);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload.err?.message || "Failed to update account."
      );
    }
  };

  const handleLaunchStack = () => {
    updateStack();
  };

  const handleCancel = () => {
    handleModal(false);
  };

  const versions = [
    { value: "0.0.1", label: "0.0.1" },
    { value: "0.0.2", label: "0.0.2" },
  ];

  useEffect(() => {
    if (
      account?.dataScanFrequency === "Monthly" ||
      account?.dataScanFrequency === "rate(30 days)"
    ) {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[2].value);
    } else if (
      account?.dataScanFrequency === "Weekly" ||
      account?.dataScanFrequency === "rate(7 days)"
    ) {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[1].value);
    } else if (
      account?.dataScanFrequency === "Daily" ||
      account?.dataScanFrequency === "rate(1 day)"
    ) {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[0].value);
    } else {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[0].value);
    }
  }, [account]);

  return (
    <div id="update-account">
      <div
        className="modal fade show"
        id="updateModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="updateModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          {/* Global loader for any api calls inside update account */}
          <BlockUi
            tag="div"
            blocking={loading}
            loader={
              <Loader
                active
                type={LOADER_CONFIG.type}
                color={LOADER_CONFIG.color}
              />
            }
          >
            <div className="modal-content">
              <div className="modal-header pl-0 d-flex justify-content-start align-items-center">
                {/* <img src={updateAccountIcon} className="mr-3" /> */}
                <span>Update Account Stack</span>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-3 pl-0"> Account ID:</div>
                  <div className="col-md-5 modal-text">{account.id}</div>
                  <div className="col-md-4 modal-text">
                    {(account.status && account.status === "creating_stack") ||
                    account.status === "24_hour_passed" ? (
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={updateLaunchStack}
                      >
                        Launch create-stack
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-3 pl-0"> External ID:</div>
                  <div className="col-md-8 modal-text">
                    {account.externalId}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-3 pl-0"> AWS Role ARN:</div>
                  <div className="col-md-8 modal-text">
                    {account.awsRoleARN}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-3 pl-0"> Stack Id:</div>
                  <div className="col-md-9 pr-0">
                    <input
                      type="text"
                      className="form-control"
                      value={stackId}
                      onChange={(e) => setStackId(e.target.value)}
                      disabled={account.status !== "active"}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-3 pl-0"> Data Scanning Frequency:</div>
                  <div className="col-md-9 pr-0">
                    <select
                      className="form-control"
                      value={dataScanFrequency}
                      onChange={(event) => {
                        setDataScanFrequency(event.target.value);
                      }}
                    >
                      <option disabled>Select Data Scanning Frequency</option>
                      {DATA_SCANNING_FREQUENCY.map((frequency, index) => (
                        <option key={index} value={frequency.value}>
                          {frequency.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row modal-message mt-4 pt-4">
                  <div className="col-md-12 pl-0">
                    Please click on update stack button to update the stack.
                  </div>
                </div>
              </div>
              <div className="modal-footer pb-4 pt-0">
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleLaunchStack}
                  disabled={loading || account.status !== "active" || !stackId}
                >
                  Update stack
                </Button>
                <span className="separator mx-3"></span>

                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => handleCancel()}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default UpdateAccountModal;
