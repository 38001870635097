import { memo, useEffect, useState } from "react";
import { useTheme } from "@mui/material";

import "./FirstAccountStats.scss";
import "./FirstAccountStatsSteps.scss";
import FirstAccountStatsSteps from "./FirstAccountStatsSteps";
import { getCustomerId } from "../../../utils/SessionHelper";
import {
  firstAccountStatStatus,
  listDatastoreStats,
} from "../../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import infoIcon from "../../../assets/img/icons/info-icon.svg";
import { COLOR_PALETTE } from "../../../utils/Constants";

const FirstAccountStats = ({ firstAccountStatus }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const stepCardsData = [
    {
      id: "TotalDataStores",
      stepNumber: 1,
      stepNumberClass: "card-number-info",
      title: "Total Data Stores",
      label: "TOTAL DATA STORES",
      score: 0,
      state: "enable",
      scoreClass: "text-info",
      datastoreStatsKey: "TotalDataStores",
      stepCompleted:
        firstAccountStatus &&
        firstAccountStatus.first_acct_classification_complete,
      cardTitle: "Risk",
      donutData: [
        {
          value: 0,
          color: COLOR_PALETTE.textInfo,
          label: "Critical",
        },
      ],
      totalValue: 100,
      defaultColor: theme.palette.surface20.main,
      variant: "AutoColor",
      innerRadius: 115,
      outerRadius: 130,
      boxSize: 300,
      donutHeight: 90,
      donutWidth: 90,
    },
    {
      id: "sensitiveData",
      stepNumber: 2,
      stepNumberClass: "card-number-warning",
      title: "Sensitive Data",
      label: "SENSITIVE DATA",
      score: 0,
      state: "enable",
      scoreClass: "text-warning",
      datastoreStatsKey: "importantRisk",
      stepCompleted:
        firstAccountStatus &&
        firstAccountStatus.first_acct_classification_complete,
      cardTitle: "Risk",
      donutData: [
        {
          value: 0,
          color: COLOR_PALETTE.textWarning,
          label: "Critical",
        },
      ],
      totalValue: 100,
      defaultColor: theme.palette.surface20.main,
      variant: "AutoColor",
      innerRadius: 115,
      outerRadius: 130,
      boxSize: 300,
      donutHeight: 90,
      donutWidth: 90,
    },
    {
      id: "highRiskStore",
      stepNumber: 3,
      stepNumberClass: "card-number-danger",
      title: "High Risk Stores",
      label: "HIGH RISK STORES",
      score: 0,
      state: `${
        firstAccountStatus &&
        firstAccountStatus.first_acct_classification_complete
          ? "enable"
          : "disable"
      }`,
      scoreClass: "text-danger",
      datastoreStatsKey: "urgentRisk",
      stepCompleted:
        firstAccountStatus && firstAccountStatus.first_acct_risk_complete,
      cardTitle: "Risk",
      donutData: [
        {
          value: 0,
          color: COLOR_PALETTE.textDanger,
          label: "Critical",
        },
      ],
      totalValue: 100,
      defaultColor: theme.palette.surface20.main,
      variant: "AutoColor",
      innerRadius: 115,
      outerRadius: 130,
      boxSize: 300,
      donutHeight: 90,
      donutWidth: 90,
    },
  ];

  const [stepCards, setStepCards] = useState(stepCardsData);
  const accounts = useSelector((state) => state.account.accounts);
  const datastoreStats = useSelector((state) => state.dashboard.datastoreStats);
  const accountAvailable = useSelector(
    (state) => state.account.accountAvailable
  );

  const getStats = async () => {
    await dispatch(
      listDatastoreStats({
        customerId: getCustomerId(),
      })
    );
  };

  const getFirstAccountStats = () => {
    const payload = {
      customerId: getCustomerId(),
    };

    dispatch(firstAccountStatStatus(payload));
  };

  const getAccountName = () => {
    if (accounts && accounts.length > 0) {
      return accounts[0].id;
    }
    return "";
  };

  useEffect(() => {
    let firstAccountInterval = null;
    if (!firstAccountStatus?.first_acct_risk_complete) {
      firstAccountInterval = setInterval(() => {
        getFirstAccountStats();
      }, 30000);
    }
    return () => {
      // clean up
      if (firstAccountInterval) {
        clearInterval(firstAccountInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (datastoreStats) {
      for (let key in datastoreStats) {
        if (datastoreStats.hasOwnProperty(key)) {
          let val = datastoreStats[key];
          if (
            firstAccountStatus &&
            !firstAccountStatus.first_acct_risk_complete
          ) {
            let index = stepCardsData.findIndex(
              (item) => item.datastoreStatsKey === key
            );
            if (stepCardsData[index]) {
              stepCardsData[index].score = val;
              stepCardsData[index].donutData[0].value = val;
            }
          }
        }
      }
      setStepCards([...stepCardsData]);
    }
  }, [datastoreStats]);

  useEffect(() => {
    if (firstAccountStatus?._id) {
      getStats();
    }
  }, [firstAccountStatus]);

  return (
    <div className="first-account-stats">
      <div className="risk-card-container">
        <div className="d-flex flex-column stats-card-container">
          <div>
            <div className="d-flex pt-3">
              <div className="col-sm-12 mb-2 page-heading">
                <h6 className="first-account-title-text">
                  {firstAccountStatus?._id &&
                  accountAvailable &&
                  !firstAccountStatus?.first_acct_classification_complete
                    ? "DISCOVERING & ANALYZING DATA STORE CONTENTS..."
                    : firstAccountStatus?._id &&
                      accountAvailable &&
                      !firstAccountStatus?.first_acct_risk_complete
                    ? "IDENTIFYING ISSUES & ATTACK PATHS..."
                    : ""}
                </h6>
              </div>
            </div>
          </div>
          <div className="d-flex">
            {stepCards.map((card, index) => (
              <FirstAccountStatsSteps
                cardDisable={stepCardsData.some(
                  (card) => card.stepCompleted !== null
                )}
                id={card.id}
                title={card.title}
                curve={card.curve}
                score={card.score}
                state={card.state}
                scoreClass={card.scoreClass}
                stepNumberClass={card.stepNumberClass}
                stepNumber={card.stepNumber}
                index={index}
                stepCompleted={card.stepCompleted}
                label={card.label}
                totalValue={card.totalValue}
                donutData={card.donutData}
                defaultColor={card.defaultColor}
                variant={card.variant}
                innerRadius={card.innerRadius}
                outerRadius={card.outerRadius}
                boxSize={card.boxSize}
                donutHeight={card.donutHeight}
                donutWidth={card.donutWidth}
                key={index}
              />
            ))}
          </div>
        </div>
        <div className="first-account-text stats-card-container">
          <div className="d-flex pl-2">
            <div>
              <img className="mr-2" src={infoIcon} />
            </div>

            <div>
              {firstAccountStatus?.first_acct_classification_complete ? (
                <p>
                  We are discovering Issues Attack Paths to your Topology. Based
                  on our discoveries, Cloud Defense ranks each Data Store’s Risk
                  by the Sensitivity of Data, Risk of Issues and how many Attack
                  Paths lead to the Data Store.
                </p>
              ) : (
                <p>
                  {`Cloud Defense is discovering Data Stores in the Account:
                      ${getAccountName()}. The Data Stores will be displayed below in
                      the order of discovery. We discover the Store Name, Type,
                      Location and other meta data.`}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FirstAccountStats);
