import { useTheme } from '@mui/material';
import * as d3 from 'd3';
import { useEffect, useState } from 'react';
import Box from './Box';
import LineChartTooltip from './LineChartTooltip';

export interface Props {
  lineData: Array<{ category: string; issues: number; date: string }>;
  classNameForGraph: string;
  categories: Array<{ name: string; color: string; label: string }>;
}

interface data {
  category: string;
  issues: number;
  date: string;
}

const LineChart = ({ lineData, classNameForGraph, categories }: Props) => {
  const [tooltipCoordinates, setTooltipCoordinates] = useState({
    x: 0,
    y: 0,
  });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [graphData, setGraphData] = useState({
    anomalousAccess: 0,
    cloudIssues: 0,
    dsActiveAnomaly: 0,
    dsAtRisk: 0,
    dsIssues: 0,
    dsNonCompliant: 0,
    liveAccess: 0,
    permissionAccess: 0,
    date: '',
  });
  const theme = useTheme();

  const styles = {
    chartContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
  };

  const getDataForDate = (date: Date) => {
    const event = new Date(date);
    let newDate = JSON.stringify(event);
    newDate = newDate.slice(1, 11);
    const anomalousAccess: number =
      lineData.find(
        (data) => data.date === newDate && data.category === 'anomalousAccess'
      )?.issues || 0;
    const cloudIssues: number =
      lineData.find(
        (data) => data.date === newDate && data.category === 'cloudIssues'
      )?.issues || 0;
    const dsActiveAnomaly: number =
      lineData.find(
        (data) => data.date === newDate && data.category === 'dsActiveAnomaly'
      )?.issues || 0;
    const dsAtRisk: number =
      lineData.find(
        (data) => data.date === newDate && data.category === 'dsAtRisk'
      )?.issues || 0;
    const dsIssues: number =
      lineData.find(
        (data) => data.date === newDate && data.category === 'dsIssues'
      )?.issues || 0;
    const dsNonCompliant: number =
      lineData.find(
        (data) => data.date === newDate && data.category === 'dsNonCompliant'
      )?.issues || 0;
    const liveAccess: number =
      lineData.find(
        (data) => data.date === newDate && data.category === 'liveAccess'
      )?.issues || 0;
    const permissionAccess: number =
      lineData.find(
        (data) => data.date === newDate && data.category === 'permissionAccess'
      )?.issues || 0;

    setGraphData({
      anomalousAccess,
      cloudIssues,
      dsActiveAnomaly,
      dsAtRisk,
      dsIssues,
      dsNonCompliant,
      liveAccess,
      permissionAccess,
      date: `${date.getDate()} ${date.toLocaleString('default', {
        month: 'long',
      })} ${date.getFullYear()}`,
    });
  };

  const drawLineChart = () => {
    const margin = { top: 10, right: 20, bottom: 50, left: 70 };
    const width = parseFloat(d3.select(`.${classNameForGraph}`).style('width'));
    const height = 250 - margin.top - margin.bottom;

    const dates: string[] = lineData.map((line) => line?.date);
    const formatDate: any = d3.timeFormat('%d %b');
    const parseDate = d3.timeParse('%Y-%m-%d');

    const xScale = d3
      .scaleTime()
      .rangeRound([0, width - margin.left - margin.right]);
    const yScale = d3.scaleLinear().rangeRound([height, 0]);
    const yAxis = d3
      .axisLeft(yScale)
      .tickSizeInner(-width)
      .tickSizeOuter(0)
      .ticks(4);
    const xExtent = d3.extent(dates, (d: string) => parseDate(d));
    const max = d3.max(lineData, (d) => d.issues);
    const diffrence = max && (Math.floor(max / 5) + 1) * 5 - max;
    const yMax = max && diffrence && max + diffrence;

    xScale.domain([xExtent[0] ?? 0, xExtent[1] ?? 1]);
    yScale.domain([0, yMax ?? 0]);
    const xAxis = d3
      .axisBottom(xScale)
      .tickValues(dates.map((date: string) => parseDate(date) as Date))
      .ticks(d3.utcDay.every(10))
      .tickFormat(formatDate)
      .tickSizeOuter(0)
      .tickSizeInner(-width);

    const dataGrouped = d3.group(lineData, (d) => d.category);

    const color = d3
      .scaleOrdinal()
      .domain(categories.map((category) => category?.name))
      .range(categories.map((category) => category?.color));

    const line: any = d3
      .line()
      .x((d: any) => {
        return xScale(parseDate(d.date) as Date);
      })
      .y((d: any) => yScale(d.issues));

    d3.select(`.${classNameForGraph}`).selectAll('svg').remove();
    const svg = d3
      .select(`.${classNameForGraph}`)
      .append('svg')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('transform', 'translate(0,0)')
      .attr('width', width)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const chart = svg.append('g');

    dataGrouped.forEach((categoryData: {}, category: string) => {
      svg
        .append('path')
        .datum(categoryData)
        .attr('class', 'multi-line')
        .attr('d', line)
        .style('stroke', (d) => color(category) as string)
        .style('stroke-width', '2px')
        .style('fill', 'none')
        .attr('stroke-dasharray', function () {
          const length: number = this.getTotalLength();
          return length + ' ' + length;
        })
        .attr('stroke-dashoffset', function () {
          const length: number = this.getTotalLength();
          return length;
        })
        .transition()
        .duration(1000)
        .attr('stroke-dashoffset', 0);
    });

    const xAxisGroup = chart
      .append('g')
      .attr('class', 'barBottomAxis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    const yAxisGroup = chart
      .append('g')
      .call(yAxis)
      .attr('class', 'barBottomAxis');
    yAxisGroup.selectAll('text').attr('x', '-8');
    yAxisGroup.selectAll('.domain').remove();
    yAxisGroup.select(':nth-child(1) line').attr('stroke-width', '1');
    yAxisGroup.selectAll('line').attr('stroke-width', '0.5');
    xAxisGroup.selectAll('.tick').selectAll('line').remove();
    xAxisGroup.selectAll('text').attr('y', '16');

    const tooltipLine = svg
      .append('rect')
      .attr('class', 'line')
      .attr('height', height + 5)
      .attr('transform', 'translate(0,-5)')
      .attr('width', 1)
      .attr('fill', theme.palette.surface80.main);

    const lines: HTMLCollectionOf<SVGGeometryElement> = document.getElementsByClassName(
      'multi-line'
    ) as HTMLCollectionOf<SVGGeometryElement>;

    const mousePerLine = svg
      .selectAll('.mouse-per-line')
      .data(d3.range(0))
      .enter()
      .append('g')
      .attr('class', 'mouse-per-line');

    mousePerLine
      .append('circle')
      .attr('r', 4)
      .style('fill', (d: number) => categories[d].color)
      .style('stroke-width', '1px')
      .style('opacity', '0');

    const onMouseMove = (event: Event) => {
      const mouse = d3.pointer(event);
      let x = mouse[0];
      const y = mouse[1];

      const value = xScale.invert(x);
      tooltipLine.attr('x', x);
      getDataForDate(value);
      setTooltipCoordinates({ x, y });
      setIsTooltipVisible(true);

      //d3.selectAll('.mouse-per-line').attr('transform', function (d, i) {
      //  let beginning = 0,
      //    end = lines[i]?.getTotalLength(),
      //    target = 0;
      //  let pos = { x: 0, y: 0 };
      //  const trueFlag = true;
      //  while (trueFlag) {
      //    target = Math.floor((beginning + end) / 2);
      //    pos = lines[i]?.getPointAtLength(target);
      //    if ((target === end || target === beginning) && pos.x !== mouse[0]) {
      //      break;
      //    }
      //    if (pos?.x > mouse[0]) end = target;
      //    else if (pos?.x < mouse[0]) beginning = target;
      //    else break;
      //  }
      //  return 'translate(' + mouse[0] + ',' + pos?.y + ')';
      //});
    };

    const onMouseOut = (event: Event) => {
      setIsTooltipVisible(false);
    };

    const mouseTracker = svg
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .style('pointer-events', 'all')
      .on('mousemove', (event) => onMouseMove(event))
      .on('mouseout', (event) => onMouseOut(event))
      .on('mouseover', () => {
        d3.selectAll('.mouse-per-line circle').style('opacity', '1');
      });
  };

  useEffect(() => {
    drawLineChart();
  }, [lineData]);

  return (
    <Box sx={styles.chartContainer} id="chart-container">
      <div className={classNameForGraph}></div>
      <div className={`${classNameForGraph}`}></div>
      {isTooltipVisible && (
        <LineChartTooltip
          coordinates={tooltipCoordinates}
          date={graphData?.date}
          data={[
            {
              color: theme.palette.peach.main,
              label: 'DS At Risk',
              value: graphData.dsAtRisk,
            },
            {
              color: theme.palette.laurelGreen.main,
              label: 'DS Non Compliant',
              value: graphData.dsNonCompliant,
            },
            {
              color: theme.palette.paleBlue.main,
              label: 'DS Active Anomaly',
              value: graphData.dsActiveAnomaly,
            },
            //{
            //  color: theme.palette.purpleStroke.main,
            //  label: 'DS Issues',
            //  value: graphData.dsIssues,
            //},
            //{
            //  color: theme.palette.pictonBlue.main,
            //  label: 'Cloud Issues',
            //  value: graphData.cloudIssues,
            //},
            //{
            //  color: theme.palette.redStroke.main,
            //  label: 'Permission Access',
            //  value: graphData.permissionAccess,
            //},
            //{
            //  color: theme.palette.mistyMoss.main,
            //  label: 'Live Access',
            //  value: graphData.liveAccess,
            //},
            //{
            //  color: theme.palette.yellowRed.main,
            //  label: 'Anomalous Access',
            //  value: graphData.anomalousAccess,
            //},
          ]}
        />
      )}
    </Box>
  );
};

export default LineChart;
