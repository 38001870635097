import { useTheme } from '@mui/material';
import Box from './Box';
import Typography from './Typography';

export interface Props {
  date: string;
  data: Array<{ color: string; label: string; value: number }>;
  coordinates?: { x: number; y: number };
}

const LineChartTooltip = ({ date, data, coordinates = { x: 0, y: 0 } }: Props) => {
  const theme = useTheme();
  const styles = {
    tooltipContainer: {
      top: coordinates?.y || 0 + 'px',
      left: (coordinates?.x || 0) + 88 + 'px',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      width: '184px',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface80.main,
      borderRadius: theme.spacing(0.5)
    },
    dateText: {
      fontWeight: 500,
      opacity: '0.5'
    },
    valueText: {
      fontWeight: 500
    },
    rowBadge: (color: string) => ({
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: '50%',
      backgroundColor: color
    }),
    dataContainer: {
      marginTop: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1)
    },
    legends: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    legendBadge: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5)
    }
  };
  return (
    <Box sx={styles.tooltipContainer}>
      <Typography variant='tooltip' color={theme.palette.surfaceMain.main} sx={styles.dateText}>
        {date}
      </Typography>
      <Box sx={styles.dataContainer}>
        {data?.map((dataItem, index) => (
          <Box sx={styles.legends} key={index}>
            <Box sx={styles.legendBadge}>
              <Box sx={styles.rowBadge(dataItem.color)}></Box>
              <Typography variant='caption' color={theme.palette.surfaceMain.main}>
                {dataItem.label}
              </Typography>
            </Box>
            <Typography
              variant='caption'
              color={theme.palette.surfaceMain.main}
              sx={styles.valueText}>
              {dataItem.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LineChartTooltip;
