import { useTheme } from "@mui/material";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { MapPlotData, RegionsTooltip } from "../../types/type";
import Box from "../common/Box";
import Loader from "../common/Loader";
import { Tab, Tabs } from "../common/Tabs";
import Typography from "../common/Typography";
import WorldMap, { CountryData } from "../common/WorldMap";
import GeoDataStoreCard from "./GeoDataStoreCard";
import { regions } from "../maps/aws_regions";
import { isContainsValue } from "../../utils/commonUtils";

const GeoDistributionCard = () => {
  const [activeTab, setActiveTab] = useState("inventory-view");
  const {
    geoDistribution,
    isFetchingGeoDistribution,
    getGeoDistributionData,
    overviewAllRegions,
    isFetchingAllRegionsOverview,
    getSensitiveData,
    overviewForSelectedRegion,
    isFetchingSelectedRegionOverview,
    getRegionsOverview,
  } = useContext(AppContext);

  const [activeRegion, setActiveRegion] = useState<{
    data: [];
    region: string;
    regionCode: string;
  }>({
    data: [],
    region: "All Regions",
    regionCode: "all",
  });
  const [geoData, setGeoData] = useState<
    Array<{ type: string; data: Array<MapPlotData> }>
  >([{ type: "", data: [] }]);
  const [tooltipData, setTooltipData] = useState<RegionsTooltip>({
    impacted: 0,
    data: [],
  });
  const [overviewAll, setOverviewAll] = useState([]);
  const [overviewRegionSpecific, setOverviewRegionSpecific] = useState([]);
  const [impactedDataStore, setImpactedDataStore] = useState(0);
  const [totalDataStore, setTotalDataStore] = useState(0);

  const theme = useTheme();
  const styles = {
    geoCard: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      position: "relative",
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    cardContent: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "65% 35%",
    },
    mapContainer: {
      width: "100%",
    },
    mapTabs: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  };

  const cardTabs = [
    {
      value: "inventory-view",
      label: "Inventory View",
      color: theme.palette.lightMagenta.main,
      stroke: theme.palette.purpleStroke.main,
    },
    //{
    //  value: 'risk-view',
    //  label: 'Risk View',
    //  color: theme.palette.critical.main,
    //  stroke: theme.palette.redStroke.main,
    //},
  ];

  useEffect(() => {
    getGeoDistributionData();
    getSensitiveData();
  }, []);

  const getImpactedDSCount = () => {
    return overviewAllRegions?.sensitive_datastore;
    //return activeRegion?.regionCode === 'all'
    //  ? overviewAllRegions?.sensitive_datastore || 0
    //  : overviewRegionSpecific?.reduce(
    //      (
    //        acc: number,
    //        curr: {
    //          dataType: string;
    //          numberOfRecords: number;
    //          totalValue: number;
    //          impacted: number;
    //        }
    //      ) => acc + curr.impacted,
    //      0
    //    );
  };

  const formatDataForMap = (data) => {
    return data.map((dataItem) => {
      const regionData = regions.find(
        (region) => region.code === dataItem?.region
      );
      return {
        type: "Feature",
        properties: {
          id: dataItem.region,
          region: dataItem?.region,
          name: regionData?.name,
          impacted: dataItem?.datastore_count_with_sensitive_data,
          hasDataResidencyViolation: dataItem?.hasDataResidencyViolation,
          hasDataStoreRisk: dataItem?.hasDataStoreRisk,
          sensitiveData: dataItem?.sensitiveData,
        },
        geometry: {
          type: "Circle",
          coordinates: [regionData?.longitude, regionData?.latitude],
        },
      };
    });
  };

  useEffect(() => {
    if (Object.values(geoDistribution).length > 0) {
      const formattedData = formatDataForMap(geoDistribution);
      const inventoryView = formattedData;
      const riskView = formattedData.filter(
        (data) => data.properties.hasDataStoreRisk
      );
      setGeoData([
        { type: "inventory-view", data: inventoryView },
        {
          type: "risk-view",
          data: riskView,
        },
      ]);
    }
  }, [geoDistribution]);

  useEffect(() => {
    if (isContainsValue(overviewAllRegions)) {
      const totalRecords = overviewAllRegions?.sensitive_data?.reduce(
        (acc: number, curr) => acc + curr.count,
        0
      );
      const overviewAll = overviewAllRegions.sensitive_data?.map((data) => ({
        dataType: data?.tag,
        numberOfRecords: data?.count,
        impacted: data?.datastores,
        totalValue: totalRecords,
      }));
      setOverviewAll(overviewAll);
      setImpactedDataStore(overviewAllRegions?.sensitive_datastore);
      setTotalDataStore(overviewAllRegions?.total_datastore);
    }
  }, [overviewAllRegions]);

  useEffect(() => {
    if (activeRegion?.regionCode !== "all")
      getRegionsOverview(activeRegion?.regionCode);
    else {
      setImpactedDataStore(overviewAllRegions?.sensitive_datastore);
      setTotalDataStore(overviewAllRegions?.total_datastore);
    }
  }, [activeRegion]);

  useEffect(() => {
    if (Object.values(overviewForSelectedRegion)?.length > 0) {
      const totalRecords = overviewForSelectedRegion?.sensitive_data?.reduce(
        (acc: number, curr) => acc + curr.count,
        0
      );
      setOverviewRegionSpecific(
        overviewForSelectedRegion?.sensitive_data?.map((data) => ({
          dataType: data?.tag,
          numberOfRecords: data?.count,
          impacted: data?.datastores,
          totalValue: totalRecords,
        }))
      );
      setImpactedDataStore(overviewForSelectedRegion?.sensitive_datastore);
      setTotalDataStore(overviewForSelectedRegion?.total_datastore);
    }
  }, [overviewForSelectedRegion]);

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setActiveRegion({ data: [], region: "All Regions", regionCode: "all" });
  };

  const handleMouseOverTooltip = (data, impacted) => {
    if (tooltipData?.data?.length === 0) {
      const recordsForTooltip =
        data?.map(({ tag, records }) => ({
          dataType: tag,
          records: records,
        })) || [];
      setTooltipData({ impacted: impacted, data: recordsForTooltip });
    }
  };

  return (
    <Box sx={styles.geoCard}>
      {isFetchingGeoDistribution ? (
        <Loader height="531px" />
      ) : (
        <>
          <Typography
            color={theme.palette.surface80.main}
            sx={styles.cardTitle}
          >
            Geo Distribution of Impacted Data Stores by Sensitive Data Type
          </Typography>
          <Box sx={styles.cardContent}>
            <Box sx={styles.mapTabs}>
              <Box>
                <Tabs value={activeTab} onChange={handleTabChange}>
                  {cardTabs.map((tab: object, index: number) => (
                    <Tab {...tab} key={index} />
                  ))}
                </Tabs>
              </Box>
              <WorldMap
                countriesToPlot={
                  geoData.find((data) => data.type === activeTab)?.data || []
                }
                activeTabData={
                  cardTabs.find((tab) => tab.value === activeTab) || cardTabs[0]
                }
                activeRegion={activeRegion}
                setActiveRegion={setActiveRegion}
                handleMouseOverTooltip={handleMouseOverTooltip}
                tooltipData={tooltipData || []}
                clearTooltipData={() =>
                  setTooltipData({
                    impacted: 0,
                    data: [],
                  })
                }
              />
            </Box>
            <GeoDataStoreCard
              barColor={
                cardTabs.find((tab) => tab?.value === activeTab)?.color || ""
              }
              impactedDS={impactedDataStore}
              totalDS={totalDataStore}
              activeRegion={activeRegion}
              dataDistribution={
                activeRegion.regionCode === "all"
                  ? overviewAll
                  : overviewRegionSpecific
              }
              isFetchingData={
                isFetchingAllRegionsOverview || isFetchingSelectedRegionOverview
              }
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default GeoDistributionCard;
