import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import LegendWithColor from "../common/LegendWithColor";
import LineChart from "../common/LineChart";
import Loader from "../common/Loader";
import Typography from "../common/Typography";
import Tooltip from "../common/Tooltip";

export interface Props {}

const RiskTrendsCard = () => {
  const [riskTrendsData, setRiskTrendsData] = useState([]);
  const { riskTrends, isFetchingRiskTrends, getRiskTrends } = useContext(
    AppContext
  );
  const theme = useTheme();
  const infoDescription =
    "History of datastores at risk, non-compliant or with active anomaly over last 7 days.";
  const styles = {
    riskTrendsCard: {
      width: "70%",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
    },
    cardHeader: {
      display: "flex",
      gap: theme.spacing(3.375),
      alignItems: "center",
    },
    cardTitle: {
      display: "flex",
      gap: theme.spacing(0.5),
      alignItems: "center",
    },
    infoIcon: {
      color: theme.palette.surface40.main,
    },
    riskTrendsText: {
      fontSize: "13px",
    },

    lineChart: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    legendContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "end",
      gap: theme.spacing(3),
    },
  };

  useEffect(() => {
    getRiskTrends();
  }, []);

  const lastSevenDays = (date) => {
    const compareDate = moment(date);
    const today = moment();
    const sevenDaysBefore = moment().subtract(7, "days");
    return compareDate.isBetween(sevenDaysBefore, today, "days", "[]");
  };

  const showCategory = (category: string) =>
    ["dsAtRisk", "dsNonCompliant", "dsActiveAnomaly"].includes(category);

  useEffect(() => {
    if (riskTrends?.length > 0) {
      let data: any = [];
      riskTrends?.forEach((item: any) => {
        for (const [key, value] of Object.entries(item)) {
          if (key !== "at" && lastSevenDays(item?.at) && showCategory(key)) {
            data.push({
              category: key,
              issues: Number(value),
              date: item?.at,
            });
          }
        }
      });
      setRiskTrendsData(data);
    }
  }, [riskTrends]);

  const categories = [
    { name: "dsAtRisk", label: "DS At Risk", color: theme.palette.peach.main },
    {
      name: "dsNonCompliant",
      label: "DS Non Compliant",
      color: theme.palette.laurelGreen.main,
    },
    {
      name: "dsActiveAnomaly",
      label: "DS Active Anomaly",
      color: theme.palette.paleBlue.main,
    },
    {
      name: "dsIssues",
      label: "DS Issues",
      color: theme.palette.purpleStroke.main,
    },
    {
      name: "cloudIssues",
      label: "Cloud Issues",
      color: theme.palette.pictonBlue.main,
    },
    {
      name: "permissionAccess",
      label: "Permission Access",
      color: theme.palette.redStroke.main,
    },
    {
      name: "liveAccess",
      label: "Live Access",
      color: theme.palette.mistyMoss.main,
    },
    {
      name: "anomalousAccess",
      label: "Anomalous Access",
      color: theme.palette.yellowRed.main,
    },
  ];

  return (
    <Box sx={styles.riskTrendsCard}>
      {isFetchingRiskTrends ? (
        <Loader height="307px" />
      ) : (
        <>
          <Box sx={styles.cardHeader}>
            <Box sx={styles.cardTitle}>
              <Typography
                color={theme.palette.surface80.main}
                sx={styles.riskTrendsText}
              >
                Risk Trends
              </Typography>
              <Tooltip placement="right" arrow title={infoDescription}>
                <InfoOutlinedIcon fontSize="inherit" sx={styles.infoIcon} />
              </Tooltip>
            </Box>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Last 7 Days
            </Typography>
          </Box>
          <Box sx={styles.lineChart}>
            <LineChart
              lineData={riskTrendsData}
              classNameForGraph="lineChartForRiskTrends"
              categories={categories}
            />
          </Box>
          <Box sx={styles.legendContainer}>
            {categories.slice(0, 3).map((category, index) => (
              <LegendWithColor key={index} {...category} />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default RiskTrendsCard;
