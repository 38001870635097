import { useTheme } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";

import { isContainsValue } from "../../utils/commonUtils";
import Box from "../common/Box";
import Loader from "../common/Loader";
import { datastoreFilter, dataStoreTypes } from "../constants/constants";
import BarChartCard from "./BarChartCard";
import { useHistory } from "react-router-dom";

type BarData = {
  barLabel: string;
  barValue: number;
  totalValue: number;
  barFaceColor: string;
  barBackColor: string;
  onClick?: () => void;
  id: number;
};

type TabData = {
  tabIndex: string;
  chartTitle: string;
  xAxisLabel: string;
  barData: Array<BarData>;
};

const DataViolationBarChart = () => {
  const theme = useTheme();
  const history = useHistory();
  const {
    dataStoreViolation,
    getDataStoreViolation,
    isFetchingStoreViolation,
    setOpenPolicyStoreDetailDrawer,
    setDrawerData,
  } = useContext(AppContext);

  const [activeTabNumber, setActiveTabNumber] = useState<string>("all");
  const [tabData, setTabData] = useState<Array<TabData>>([]);

  const styles = {
    barCard: {
      width: "50%",
      paddingY: theme.spacing(1),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
    },
  };

  useEffect(() => {
    getDataStoreViolation();
  }, []);

  useEffect(() => {
    if (dataStoreViolation.length > 0) {
      const tabDataList = dataStoreTypes.map((tab) => {
        const tabDataStore = dataStoreViolation.find(
          (data) => data.type === tab.label
        )?.data;
        const totalChartValue = Math.max(
          ...tabDataStore.map((item: { count: number }) => item.count)
        );
        return {
          tabIndex: tab.value,
          chartTitle: `${tabDataStore.length} Impacted Data Store Types`,
          xAxisLabel: "# of Data Stores",
          barData: tabDataStore
            ?.sort((a, b) => b.count - a.count)
            ?.map((dataItem, index) => ({
              barLabel: dataItem?.datastoreType,
              barValue: dataItem?.count,
              totalValue: totalChartValue,
              barFaceColor: theme.palette.lightMagenta.main,
              barBackColor: theme.palette.surface20.main,
              id: index,
            })),
        };
      });
      setTabData(tabDataList);
    }
  }, [dataStoreViolation]);

  return (
    <Box sx={styles.barCard}>
      {isFetchingStoreViolation ? (
        <Loader />
      ) : (
        <BarChartCard
          listOfTabs={dataStoreTypes}
          activeTabNumber={activeTabNumber}
          tabData={tabData}
          tabTitle="Data Store"
          openDrawer={false}
          buttonText="View All"
        />
      )}
    </Box>
  );
};

export default DataViolationBarChart;
