import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@mui/material";
import { DonutData } from "../../types/type";
import Box from "../common/Box";
import DonutChart from "../common/DonutChart";
import ImpactDonutTooltip from "../common/ImpactDonutTooltip";
import Tooltip from "../common/Tooltip";
import Typography from "../common/Typography";

const ImpactedDataCard = ({
  cardTitle,
  donutData,
  totalValue,
  defaultColor,
  variant,
  innerRadius,
  outerRadius,
  boxSize,
  donutHeight,
  donutWidth,
  infoDescription,
}: DonutData) => {
  const theme = useTheme();
  const donutTotal = donutData.reduce(
    (addedValue, item) => addedValue + item.value,
    0
  );
  const styles = {
    cardContainer: {
      width: "100%",
      border: "0.5px solid transparent",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingY: theme.spacing(2),
      position: "relative",
      cursor: "pointer",
      zIndex: 20,
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      "&:hover": {
        backgroundColor: theme.palette.blueGrey.main,
      },
    },
    donutTextContainer: {
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
    },
    cardTitle: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    donutValueText: {
      fontFamily: "Manrope",
      display: "flex",
      alignItems: "end",
      fontSize: "30px",
    },
    donutTotalText: {
      fontFamily: "Manrope",
      marginBottom: theme.spacing(0.25),
    },
    donutContainer: {
      height: "60%",
      minHeight: "60%",
      display: "flex",
      alignItems: "end",
    },
    overlayCard: {
      height: "100%",
      width: "100%",
      position: "absolute",
      border: "0.5px solid transparent",
      transition: "all 200ms ease-in-out",
      padding: "30px 0",
    },
    contentCard: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    infoIcon: {
      color: theme.palette.surface40.main,
      height: "16px",
      width: "16px",
    },
    donutTooltip: {
      tooltip: {
        sx: {
          bgcolor: theme.palette.surface80.main,
          "& .MuiTooltip-arrow": {
            color: theme.palette.surface80.main,
          },
        },
      },
    },
  };

  const getDonutTooltipData = () => {
    if (donutData[0]?.data) {
      return donutData.map((donut) => {
        return {
          label: donut?.label,
          critical:
            donut?.data?.find((dataItem) => dataItem?.label === "Critical")
              ?.value || 0,
          high:
            donut?.data?.find((dataItem) => dataItem?.label === "High")
              ?.value || 0,
        };
      });
    } else {
      return [
        {
          label: cardTitle,
          critical:
            donutData.find((data) => data.label === "Critical")?.value || 0,
          high: donutData.find((data) => data.label === "High")?.value || 0,
        },
      ];
    }
  };

  return (
    <Box sx={styles.cardContainer} className="impacted_cardContainer">
      <Box sx={styles.contentCard}>
        <Box sx={styles.titleContainer}>
          <Typography
            variant="caption"
            color={theme.palette.surface80.main}
            sx={styles.cardTitle}
          >
            {cardTitle}
          </Typography>
          <Tooltip placement="right" arrow title={infoDescription}>
            <InfoOutlinedIcon sx={styles.infoIcon} />
          </Tooltip>
        </Box>
        <Box sx={styles.donutContainer}>
          <Tooltip
            placement="right-end"
            arrow
            title={<ImpactDonutTooltip tooltipData={getDonutTooltipData()} />}
            componentsProps={styles.donutTooltip}
          >
            <div>
              <DonutChart
                donutData={donutData}
                totalValue={totalValue}
                defaultColor={defaultColor}
                variant={variant}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                boxSize={boxSize}
                donutHeight={donutHeight}
                donutWidth={donutWidth}
              />
            </div>
          </Tooltip>
        </Box>
        <Box sx={styles.donutTextContainer}>
          <Typography
            variant="h4"
            color={theme.palette.surface80.main}
            sx={styles.donutValueText}
          >
            {donutTotal}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ImpactedDataCard;
