import axios from "axios";
import React, { useState, useEffect } from "react";
import CreateUser from "../../components/user-management/create-user";
import Table from "../../components/user-management/table";
import UpdateUser from "../../components/user-management/update-user";
import {
  getCustomerId,
  getCustomerRef,
  getUserId,
} from "../../utils/SessionHelper";
import { Toaster } from "../../utils/toaster";
import FormatUrl from "../../utils/UrlFormatter";
import "./style.scss";
import { confirmAlert } from "react-confirm-alert";
import Button from "../../components/common/Button";
import UserTable from "../../components/user-management/table";
import Box from "../../components/common/Box";
import { useTheme } from "@mui/material";
import Typography from "../../components/common/Typography";
import { DeleteModal } from "../../components/common/DeleteModal";

const UserManagement = ({ setPath }) => {
  const [users, setUsers] = useState([]);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [user, setUser] = useState({});
  const theme = useTheme();

  const styles = {
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      height: "calc(100vh - 182px)",
      overflow: "hidden",
    },
    cardHeader: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    userTable: {
      display: "flex",
      flex: 1,
      overflow: "hidden",
    },
  };

  const getUsers = async () => {
    let url = FormatUrl(`/user/listUserUnderSameCustomer/${getCustomerRef()}`);
    fetch(url)
      .then((response) => response.json())
      .then((data) => setUsers(data && data.data ? data.data : []))
      .catch((err) => {
        setUsers([]);
      });
  };

  const ConfirmDeleteUser = (id) => {
    let url = FormatUrl(`/user/user/${id}`);
    axios
      .delete(url)
      .then(function (response, error) {
        let data = response.data;
        getUsers();
        Toaster(1, "User deleted");
        setIsDeleteModal(null);
      })
      .catch(function (error) {
        Toaster(2, "Failed to delete user");
        setIsDeleteModal(null);
      });
  };

  const deleteUser = (id) => {
    confirmAlert({
      title: `Confirm to delete`,
      message: `Are you sure you wants to delete this user`,
      buttons: [
        {
          label: "Yes",
          onClick: () => ConfirmDeleteUser(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const updateRole = async (id, role) => {
    let url = FormatUrl(`/user/user/${id}`);

    axios
      .patch(url, {
        role: role,
      })
      .then(function (response, error) {
        let data = response.data;
        getUsers();
        Toaster(1, "Role updated");
      })
      .catch(function (error) {
        if (error.response) {
          Toaster(
            2,
            error.response?.data?.err?.message || "Failed to update user"
          );
        } else {
          Toaster(2, "Failed to update user");
        }
      });
  };

  const onboardUser = (user_id, email) => {
    let url = FormatUrl(`/email/onboarding`);
    axios
      .post(url, {
        user_id: user_id,
        email: email,
      })
      .then(function (response, error) {
        let data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateStatus = async (id, status, email) => {
    let url = FormatUrl(`/user/user/${id}`);
    axios
      .patch(url, {
        status: status,
      })
      .then(function (response, error) {
        let data = response.data;
        getUsers();
        Toaster(1, "Status updated");
        if (status === "ACTIVE") {
          onboardUser(id, email);
        }
      })
      .catch(function (error) {
        if (error.response) {
          Toaster(
            2,
            error.response?.data?.err?.message || "Failed to update user"
          );
        } else {
          Toaster(2, "Failed to update user");
        }
      });
  };

  useEffect(() => {
    document.title = "Cloud Defense - User Management";
    setPath(window.location.pathname);
    getUsers();
  }, []);

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.cardHeader}>
        <Typography variant="body2" color={theme.palette.surface80.main}>
          User Management
        </Typography>

        <Button
          variant="contained"
          type="button"
          onClick={() => setCreateUserModal(true)}
        >
          Create User
        </Button>
      </Box>
      <Box sx={styles.userTable}>
        <UserTable
          users={users}
          setCreateUserModal={setCreateUserModal}
          setUpdateUserModal={setUpdateUserModal}
          deleteUser={(id) => setIsDeleteModal(id)}
          updateRole={updateRole}
          updateStatus={updateStatus}
        />
        <DeleteModal
          open={isDeleteModal}
          title="Confirm to delete"
          description="Are you sure you wants to delete this user"
          onClose={() => setIsDeleteModal(null)}
          onDelete={() => ConfirmDeleteUser(isDeleteModal)}
        />
      </Box>

      {createUserModal ? (
        <CreateUser getUsers={getUsers} handleModal={setCreateUserModal} />
      ) : (
        ""
      )}
      {updateUserModal ? (
        <UpdateUser
          getUsers={getUsers}
          handleModal={setUpdateUserModal}
          user={user}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default UserManagement;
