import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { DonutData } from "../../types/type";
import Box from "../common/Box";
import Divider from "../common/Divider";
import LegendWithColor from "../common/LegendWithColor";
import Loader from "../common/Loader";
import Typography from "../common/Typography";
import ImpactedDataCard from "./ImpactedDonutCard";

const ImpactedDataStoresPanel = () => {
  const {
    impactedDataStores,
    isFetchingImpactedStoresData,
    getImpactedStoresData,
  } = useContext(AppContext);
  const [donutData, setDonutData] = useState<Array<DonutData>>([]);

  useEffect(() => {
    getImpactedStoresData();
  }, []);

  useEffect(() => {
    if (Object.values(impactedDataStores).length > 0) {
      const donutData = [
        {
          cardTitle: "Risk",
          donutData: [
            {
              value: impactedDataStores?.datastores_with_critical_risk || 0,
              color: theme.palette.critical.main,
              label: "Critical",
            },
            {
              value: impactedDataStores?.datastores_with_high_risk || 0,
              color: theme.palette.high.main,
              label: "High",
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
          infoDescription: "Number of datastores with critical risks.",
        },
        {
          cardTitle: "Policy Violations",
          donutData: [
            {
              value: impactedDataStores?.datastores_violating_policy || 0,
              color: theme.palette.critical.main,
              label: "Critical",
            },
            {
              value: 0,
              color: theme.palette.high.main,
              label: "High",
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
          infoDescription:
            "Number of datastores with critical policy violations.",
        },
        {
          cardTitle: "Sensitive Data",
          donutData: [
            {
              value: impactedDataStores?.datastores_with_sensitive_data || 0,
              color: theme.palette.critical.main,
              label: "Critical",
            },
            {
              value: 0,
              color: theme.palette.high.main,
              label: "High",
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
          infoDescription:
            "Number of datastores with sensitive or regulated data.",
        },
        {
          cardTitle: "Shadow",
          donutData: [
            {
              value: impactedDataStores?.shadow_datastores + 0,
              color: theme.palette.lightMagenta.main,
              label: "Shadow Data",
              data: [
                {
                  value: impactedDataStores?.shadow_datastores || 0,
                  color: theme.palette.critical.main,
                  label: "Critical",
                },
                {
                  value: 0,
                  color: theme.palette.high.main,
                  label: "High",
                },
              ],
            },
            {
              value: impactedDataStores?.shadow_ai_datastores + 0,
              color: theme.palette.paleBlue.main,
              label: "Shadow AI/LLM",
              data: [
                {
                  value: impactedDataStores?.shadow_ai_datastores || 0,
                  color: theme.palette.critical.main,
                  label: "Critical",
                },
                {
                  value: 0,
                  color: theme.palette.high.main,
                  label: "High",
                },
              ],
            },
          ],
          totalValue: impactedDataStores?.datastores || 0,
          defaultColor: theme.palette.surface20.main,
          variant: "AutoColor",
          innerRadius: 115,
          outerRadius: 130,
          boxSize: 300,
          donutHeight: 90,
          donutWidth: 90,
          infoDescription: "Number of datastores that are not managed by IT.",
        },
      ];
      setDonutData(donutData);
    }
  }, [impactedDataStores]);

  const theme = useTheme();
  const styles = {
    donutCardContainer: {
      position: "relative",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      width: "70%",
      display: "flex",
    },
    allDataImpactedText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
      maxWidth: "5rem",
    },
    sensitiveDataText: {
      color: theme.palette.surface70.main,
      fontWeight: theme.typography.fontWeightRegular,
      maxWidth: "9.375rem",
    },
    highlightDataText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    legendContainer: {
      position: "absolute",
      bottom: 0,
      right: 0,
      display: "flex",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      alignItems: "center",
      zIndex: 20,
    },
    dividerStyles: {
      height: "13px",
      alignSelf: "center",
      backgroundColor: theme.palette.surface40.main,
      marginY: 0,
    },
    legendGroup: {
      display: "flex",
      gap: theme.spacing(3),
    },
    totalDataStore: {
      opacity: 0.5,
    },
  };

  const donutLegends = [
    {
      label: "Critical",
      color: theme.palette.critical.main,
    },
    {
      label: "High",
      color: theme.palette.high.main,
    },
    {
      label: "Shadow Data",
      color: theme.palette.lightMagenta.main,
    },
    {
      label: "Shadow AI/LLM",
      color: theme.palette.lightBlue.main,
    },
  ];

  return (
    <Box sx={styles.donutCardContainer}>
      {isFetchingImpactedStoresData ? (
        <Loader />
      ) : (
        <>
          {donutData.map(
            (
              {
                cardTitle,
                donutData,
                totalValue,
                defaultColor,
                variant,
                innerRadius,
                outerRadius,
                boxSize,
                donutHeight,
                donutWidth,
                infoDescription,
              },
              index
            ) => (
              <ImpactedDataCard
                cardTitle={cardTitle}
                donutData={donutData}
                totalValue={totalValue}
                defaultColor={defaultColor}
                variant={variant}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                boxSize={boxSize}
                donutHeight={donutHeight}
                donutWidth={donutWidth}
                key={index}
                infoDescription={infoDescription}
              />
            )
          )}
          <Box sx={styles.legendContainer}>
            <Typography
              variant="caption"
              color={theme.palette.surface80.main}
              sx={styles.totalDataStore}
            >
              Total Data Store: {impactedDataStores?.datastores || 0}
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={styles.dividerStyles}
            />
            <Box sx={styles.legendGroup}>
              {donutLegends.map((legend, index) => (
                <LegendWithColor key={index} {...legend} />
              ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ImpactedDataStoresPanel;
