import { getUserId } from "../../utils/SessionHelper";
import DeleteIcon from "../../assets/img/delete.svg";
import {
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
} from "../common/Table";
import Box from "../common/Box";
import Typography from "../common/Typography";
import { useTheme } from "@mui/material";

const UserTable = ({
  users,
  setCreateUserModal,
  deleteUser,
  updateRole,
  updateStatus,
}) => {
  const theme = useTheme();
  const styles = {
    tableContainer: {},
    tableCard: {
      width: "100%",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
    },
  };
  return (
    <Box sx={styles.tableCard}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {user.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {user.first_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {user.last_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <select
                      className="form-control user-dropdown"
                      value={user.status}
                      onChange={(e) =>
                        updateStatus(user._id, e.target.value, user.email)
                      }
                    >
                      <option value={"IN-ACTIVE"}>PENDING</option>
                      <option value={"ACTIVE"}>ACTIVE</option>
                    </select>
                  </TableCell>
                  <TableCell>
                    <select
                      className="form-control user-dropdown"
                      value={user.role}
                      onChange={(e) => updateRole(user._id, e.target.value)}
                    >
                      <option value={"ADMIN"}>ADMIN</option>
                      <option value={"OPERATOR"}>OPERATOR</option>
                    </select>
                  </TableCell>
                  <TableCell>
                    {user._id === getUserId() ? "" : () => user._id}
                    <img
                      alt="Delete icon"
                      src={DeleteIcon}
                      className="ml-2 mr-2"
                      onClick={
                        user._id === getUserId()
                          ? ""
                          : () => deleteUser(user._id)
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserTable;
