import { useTheme } from "@mui/material";
import MUITable, { TableProps } from "@mui/material/Table";
import MUITableBody, { TableBodyProps } from "@mui/material/TableBody";
import MUITableCell, { TableCellProps } from "@mui/material/TableCell";
import MUITableContainer, {
  TableContainerProps,
} from "@mui/material/TableContainer";
import MUITableHead, { TableHeadProps } from "@mui/material/TableHead";
import MUITableRow, { TableRowProps } from "@mui/material/TableRow";

interface TableContainerCustomProps extends TableContainerProps {
  sx?: {};
}

export const Table = (props: TableProps) => {
  return <MUITable {...props} stickyHeader />;
};
export const TableContainer = (props: TableContainerCustomProps) => {
  const styles = {
    tableContainer: {
      maxHeight: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  };
  return (
    <MUITableContainer
      {...props}
      sx={{ ...styles.tableContainer, ...props?.sx }}
    />
  );
};

export const TableBody = (props: TableBodyProps) => {
  const theme = useTheme();
  const styles = {
    tableBody: {
      "&.MuiTableBody-root .MuiTableRow-root:hover": {
        backgroundColor: theme.palette.surface0.main,
      },
      ...props?.sx,
    },
  };
  return <MUITableBody sx={styles.tableBody} {...props} />;
};

export const TableHead = (props, { sx }: TableHeadProps) => {
  const theme = useTheme();
  const styles = {
    tableHead: {
      zIndex: 1,
      position: "relative",
      "& .MuiTableCell-head": {
        color: theme.palette.surface50.main,
        fontSize: "13px",
        lineHeight: "1.125rem",
        background: theme.palette.surface10.main,
        borderBottom: `1px solid ${theme.palette.surface20.main} !important`,
        paddingY: theme.spacing(1.25),
        zIndex: 0,
      },
    },
    ...sx,
  };
  return <MUITableHead sx={styles.tableHead} {...props} />;
};

export const TableRow = ({ selected, sx, ...otherProps }: TableRowProps) => {
  const theme = useTheme();
  const styles = {
    tableRow: {
      "&.MuiTableRow-root": {
        backgroundColor: selected ? theme.palette.surface0.main : null,
        verticalAlign: "top",
      },
      "&.MuiTableRow-root .MuiTableCell-root:first-child": {
        borderLeft: `1px solid ${
          selected ? theme.palette.primaryBlue.main : "transparent"
        }`,
      },
    },
    ...sx,
  };

  return <MUITableRow sx={styles.tableRow} {...otherProps} />;
};

export const TableCell = ({ sx, ...props }: TableCellProps) => {
  const theme = useTheme();

  const styles = {
    tableCell: {
      fontSize: "13px",
      padding: theme.spacing(2),
      verticalAlign: "middle",
      "&.MuiTableCell-root": {
        paddingX: theme.spacing(2),
        border: "none",
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
      ...sx,
    },
  };
  return <MUITableCell sx={styles.tableCell} {...props} />;
};
