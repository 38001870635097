import { SxProps, Theme, ToggleButtonGroupProps, useTheme } from '@mui/material';
import type { ToggleButtonProps } from '@mui/material/ToggleButton';
import MUIToggleButton from '@mui/material/ToggleButton';
import MUIToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from './Paper';

interface MUIToggleButtonProps extends ToggleButtonProps {
  icon?: any;
}

const ToggleButton = ({ icon, ...props }: MUIToggleButtonProps) => {
  const theme = useTheme();
  const styles: { toggleButtons: SxProps<Theme> | undefined } = {
    toggleButtons: {
      '&.MuiToggleButton-root': {
        display: 'flex',
        gap: theme.spacing(0.5),
        backgroundColor: theme.palette.surface10.main,
        color: theme.palette.surface40.main,
        textTransform: 'none',
        border: '0.5px solid transparent',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.caption,
        padding: theme.spacing(0, 1.5),

        boxSizing: 'border-box',
        width: '70px',
        '&:hover': {
          backgroundColor: theme.palette.surface0.main
        }
      },
      '&.Mui-selected': {
        borderColor: `${theme.palette.primaryBlue.main} !important`,
        color: theme.palette.primaryBlue.main,
        backgroundColor: theme.palette.surface0.main
      }
    }
  };
  return (
    <MUIToggleButton sx={styles.toggleButtons} {...props}>
      {icon ? icon : <></>} {props.value}
    </MUIToggleButton>
  );
};
export interface Props extends ToggleButtonGroupProps {
  options: { id: string; label: string; icon: any }[];
}

export const ToggleButtonGroup = ({ options, ...props }: Props) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: theme.palette.surface10.main,
        display: 'flex',
        height: '30px',
        alignItems: 'center'
      }}>
      <MUIToggleButtonGroup
        sx={{
          '& .MuiToggleButtonGroup-grouped': {
            height: '26px',
            display: 'flex',
            gap: '20px',
            margin: theme.spacing(0.25),
            '&:not(:first-of-type)': {
              borderRadius: theme.spacing(0.5)
            },
            '&:first-of-type': {
              borderRadius: theme.spacing(0.5)
            }
          }
        }}
        {...props}>
        {options.map((option: { id: string; label: string; icon: any }) => (
          <ToggleButton key={option.id} value={option?.label} icon={option?.icon} />
        ))}
      </MUIToggleButtonGroup>
    </Paper>
  );
};
