import GridViewIcon from "@mui/icons-material/GridView";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import RiskScore from "../dashboard-widgets/RiskScore";
import Box from "./Box";
import Divider from "./Divider";
import { getUserId } from "../../utils/SessionHelper";
import { Select } from "./Select";
import { DropdownSelect } from "./Dropdown";
import { ToggleButtonGroup } from "./ToggleButton";
import Typography from "./Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

import { Menu, MenuItem } from "./Menu";
import FormatUrl from "../../utils/UrlFormatter";
import { getSelectedDatastoreAccount } from "../../utils/SessionHelper";
import {
  issueViewVisible,
  issueViewDisable,
  manageDashboardViewSlice,
} from "../../redux/slices/dashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const Header = ({
  selectedAccount,
  expandedSidebar,
  setSelectedAccount,
  setInfoModal,
  isIssueViewVisible,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const [
    accountMenuAnchor,
    setAccountMenuAnchor,
  ] = useState<null | HTMLElement>(null);
  const open = Boolean(accountMenuAnchor);

  const defaultAccount = getSelectedDatastoreAccount() || {
    value: "all",
    label: "All",
  };
  const [accountList, setAccountList] = useState([
    { value: "all", label: "All" },
  ]);
  const accounts = useSelector((state: any) => state.account.accounts);

  const styles = {
    headerContainer: {
      width: "100%",
      display: "flex",
    },
    accountDetails: {
      display: "flex",
      marginLeft: "auto",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    dividerStyles: {
      height: "13px",
      alignSelf: "center",
      backgroundColor: theme.palette.surface20.main,
    },
    pointer: {
      cursor: "pointer",
    },
    noBottomBorder: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    accountSelectControl: {
      height: "height",
      "& .MuiSvgIcon-root": {
        color: theme.palette.surface70.main,
      },
    },
    menuItems: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "left",
      "&.MuiList-root": {
        paddingX: "0 !important",
      },
    },
    clickableIcons: {
      cursor: "pointer",
      color: theme.palette.surface30.main,
      "&:hover": {
        color: theme.palette.surface20.main,
      },
    },
    menuAccountDetails: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      width: "180px",
      textAlign: "left",
      gap: theme.spacing(1),
    },
    accountText: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    textBold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    accountName: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    menuItem: {
      display: "flex",
      justifyContent: "center",
    },
  };

  const toggleButtons = [
    {
      id: "data",
      label: "Data",
      icon: <GridViewIcon fontSize="inherit" />,
    },
    {
      id: "issues",
      label: "Issues",
      icon: <SplitscreenIcon fontSize="inherit" />,
    },
  ];

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    setSelectedButton(newValue);
    toggleView();
  };

  const handleOpenAccountMenu = (event) => {
    setAccountMenuAnchor(event.currentTarget);
  };

  const logoutUser = () => {
    const userId = getUserId();
    localStorage.clear();
    sessionStorage.clear();
    if (userId) {
      const url = FormatUrl(`/user/logout/${userId}`);
      window.location = url;
    } else {
      window.location.href = "/";
    }
  };

  const toggleView = () => {
    if (isIssueViewVisible) {
      dispatch(issueViewDisable());
      dispatch(
        manageDashboardViewSlice({
          userId: getUserId(),
          view: "data",
        })
      );
    } else {
      dispatch(issueViewVisible());
      dispatch(
        manageDashboardViewSlice({
          userId: getUserId(),
          view: "issue",
        })
      );
    }
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setAccountList([
        { value: "all", label: "All" },
        ...accounts.map((account) => {
          return { value: account.id, label: account.id };
        }),
      ]);
    }
  }, [accounts]);

  return (
    <Box sx={styles.headerContainer}>
      <RiskScore />
      <Box sx={styles.accountDetails}>
        <Typography
          variant="caption"
          color={theme.palette.surface70.main}
          sx={styles.pointer}
        >
          {/* <DropdownSelect
            accountList={accountList}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
       */}
          <Select
            label="Select Account"
            variant="standard"
            renderValue={() =>
              `${selectedAccount?.label}${
                selectedAccount?.label === "All" ? " Accounts" : ""
              }`
            }
            defaultValue={accountList.length > 0 && accountList[0]}
            value={() => {
              const res = accountList.find(
                (element) => element.value === selectedAccount.value
              )
                ? selectedAccount?.value
                : accountList[0]?.value;
              return res;
            }}
            options={accountList}
            sx={styles?.noBottomBorder}
            onChange={(event) =>
              setSelectedAccount(
                accountList.find(
                  (account) => account?.value === event.target.value
                )
              )
            }
          />
        </Typography>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={styles.dividerStyles}
        />
        <ToggleButtonGroup
          exclusive
          options={toggleButtons}
          value={selectedButton || toggleButtons[0]?.label}
          onChange={handleChange}
        />
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={styles.dividerStyles}
        />

        <span title="Profile">
          <AccountCircleIcon
            onClick={(event) => handleOpenAccountMenu(event)}
            sx={styles.clickableIcons}
          />
        </span>
        <span title="Notification">
          <NotificationsIcon sx={styles.clickableIcons} />
        </span>

        <span title="Help">
          <HelpRoundedIcon
            onClick={() => setInfoModal(true)}
            sx={styles.clickableIcons}
          />
        </span>

        <Menu
          open={open}
          anchorEl={accountMenuAnchor}
          onClose={() => setAccountMenuAnchor(null)}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box sx={styles.menuAccountDetails}>
            <Box sx={styles.accountText}>
              <AccountCircleOutlinedIcon />
              <Box sx={styles.accountName}>
                <Typography
                  color={theme.palette.surface0.main}
                  variant="body2"
                  sx={styles.textBold}
                >
                  {localStorage.getItem("username")}
                </Typography>
                <Typography
                  color={theme.palette.surface0.main}
                  variant="tooltip"
                >
                  {localStorage.getItem("customer_name")}
                </Typography>
              </Box>
            </Box>
            <Divider variant="fullWidth" color={theme.palette.surface20.main} />
          </Box>
          <MenuItem onClick={logoutUser} sx={styles.menuItem}>
            <Typography color={theme.palette.surface0.main} variant="caption">
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Header;
