import { axiosPrivate } from "../../utils/ApiHandler";
import { getCustomerId, getUserId } from "../../utils/SessionHelper";
import { axiosDenaliPrivate } from "../../utils/ApiHandler";

/**
 * Provides the list of all Policys.
 * @returns -- Accounts list
 */
export const fetchPolicies = () => {
  return axiosPrivate.get(`/policy/policies/${getCustomerId()}`);
};

/**
 * Provides the list of all Policys.
 * @returns -- Accounts list
 */
export const getPolicies = (params) => {
  return axiosPrivate.post(`/policy/predefined`, params);
};

/**
 * Add the Policy with the specified account details.
 * @param {object} params -- payload params
 * @returns the account details added.
 */
export const addPolicy = (params) => {
  return axiosPrivate.post(`/policy/policy`, params);
};

export const updatePolicy = (id, params) => {
  return axiosPrivate.patch(`/policy/policy/${id}`, params);
};

/**
 * Delete the Policy with the specified account Id.
 * @param {object} accountId -- account Id to be deleted
 */
export const deletePolicy = (PolicyId) => {
  return axiosPrivate.delete(`/policy/policy/${PolicyId}`);
};

/**
 * Provides the list of all Policy stats.
 * @returns -- Policy stats list
 */
export const getPolicyStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/datastore/policystats`, params);
};

/**
 * Accept the policy violation.
 * @returns -- Policy stats list
 */
export const acceptPolicyViolation = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/policies/acceptviolation`, params);
};

/**
 * Accept the policy violation.
 * @returns -- Policy stats list
 */
export const policyNotify = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/policies/notification`, params);
};

/**
 * Accept the policy violation.
 * @returns -- Policy stats list
 */
export const archivePolicy = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/policy/archive`, params);
};


/**
 * Accept the policy violation.
 * @returns -- Policy stats list
 */
export const policyViolationDetails = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/policyviolation/details`, params);
};
