import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { UsageCardProps } from "../../types/type";
import {
  getNumberAbbreviation,
  isContainsValue,
} from "../../utils/commonUtils";
import Box from "../common/Box";
import Loader from "../common/Loader";
import Typography from "../common/Typography";

const UsageCard = () => {
  const { resourcesData, isFetchingImpactedStoresData, getImpactedStoresData } =
    useContext(AppContext);
  const [resources, setResources] = useState<UsageCardProps>({
    deltaIncrease: true,
    currentResources: 0,
    deltaResources: 0,
    increaseInPercent: 0,
    users: 0,
  });
  const theme = useTheme();

  useEffect(() => {
    getImpactedStoresData();
  }, []);

  useEffect(() => {
    if (isContainsValue(resourcesData)) {
      const increaseFlag = resourcesData?.delta30_resources >= 0;
      const increaseValue =
        resourcesData?.current_resources !== 0 &&
        resourcesData?.delta30_resources !== 0
          ? increaseFlag
            ? (resourcesData?.delta30_resources /
                resourcesData?.current_resources) *
              100
            : ((resourcesData?.delta30_resources * -1) /
                resourcesData?.current_resources) *
              100
          : 0;
      setResources({
        deltaIncrease: increaseFlag,
        currentResources: resourcesData?.current_resources,
        deltaResources: resourcesData?.delta30_resources,
        increaseInPercent: Number(increaseValue.toFixed(2)),
        users: resourcesData?.current_users,
      });
    }
  }, [resourcesData]);

  const styles = {
    cardContainer: {
      width: "100%",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      padding: theme.spacing(2),
    },
    cloudResourcesContainer: {
      display: "flex",
    },
    resourcesTextContainer: {
      display: "flex",
      flexDirection: "column",
    },
    largeContainer: {
      width: "66%",
    },
    smallContainer: {
      width: "33%",
    },
    resourcesText: {
      display: "flex",
      gap: theme.spacing(0.25),
      alignItems: "end",
    },
    resourcePercent: {
      display: "flex",
      gap: theme.spacing(0.25),
      alignItems: "center",
    },
    noOfResourcesText: {
      fontFamily: "Manrope",
      fontSize: "28px",
    },
    cardTitle: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    upArrowIcon: {
      height: "14px",
      width: "14px",
      color: theme.palette.critical.main,
    },
  };

  return (
    <Box sx={styles.cardContainer}>
      {isFetchingImpactedStoresData ? (
        <Loader height="68px" />
      ) : (
        <>
          <Typography
            variant="caption"
            color={theme.palette.surface80.main}
            sx={styles.cardTitle}
          >
            Last 30 Days
          </Typography>
          <Box sx={styles.cloudResourcesContainer}>
            <Box
              sx={{
                ...styles.resourcesTextContainer,
                ...styles.largeContainer,
              }}
            >
              <Box sx={styles.resourcesText}>
                <Typography
                  color={theme.palette.surface70.main}
                  sx={styles.noOfResourcesText}
                >
                  {getNumberAbbreviation(resources?.currentResources)}
                </Typography>
                <Box sx={styles.resourcePercent}>
                  {resources.deltaIncrease ? (
                    <ArrowUpwardIcon sx={styles.upArrowIcon} />
                  ) : (
                    <ArrowDownwardIcon sx={styles.upArrowIcon} />
                  )}
                  <Typography
                    variant="caption"
                    color={theme.palette.critical.main}
                  >
                    {resources.increaseInPercent}% (
                    {getNumberAbbreviation(resources?.deltaResources)})
                  </Typography>
                </Box>
              </Box>
              <Typography
                color={theme.palette.surface70.main}
                variant="caption"
              >
                Cloud Resources
              </Typography>
            </Box>
            <Box
              sx={{
                ...styles.resourcesTextContainer,
                ...styles.smallContainer,
              }}
            >
              <Typography
                color={theme.palette.surface70.main}
                sx={styles.noOfResourcesText}
              >
                {resources?.users}
              </Typography>
              <Typography
                color={theme.palette.surface70.main}
                variant="caption"
              >
                Users
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default UsageCard;
