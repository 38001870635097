import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import DonutChart from "../common/DonutChart";
import Loader from "../common/Loader";
import Slider from "../common/Slider";
import TextButton from "../common/TextButton";
import Tooltip from "../common/Tooltip";
import Typography from "../common/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  getFormattedTitle,
  getNumberAbbreviation,
  isContainsValue,
} from "../../utils/commonUtils";
import { useHistory } from "react-router-dom";
import { CLOUD_ENV } from "../../routes/constants";

export interface Props {}

export const cloudRiskDonutChartTheme = [
  {
    color: "#ACCB9E",
  },
  {
    color: "#92CAFF",
  },
  {
    color: "#A75E6E",
  },
  {
    color: "#96DFD8",
  },
  {
    color: "#F5B483",
  },
];

const CloudRiskCard = () => {
  const {
    impactedDataStores,
    cloudRiskData,
    isFetchingCloudRiskData,
    getCloudRiskData,
  } = useContext(AppContext);
  const [firstRender, setFirstRender] = useState(false);
  const history = useHistory();
  const [dataStoreCount, setDataStoreCount] = useState(0);
  const [cloudRisk, setCloudRisk] = useState<{
    totalValue: number;
    defaultColor: string;
    innerRadius: number | undefined;
    outerRadius: number | undefined;
    boxSize: number | undefined;
    donutHeight: number;
    donutWidth: number;
  }>({
    totalValue: 0,
    defaultColor: "",
    innerRadius: 0,
    outerRadius: 0,
    boxSize: 0,
    donutHeight: 0,
    donutWidth: 0,
  });
  const [donutData, setDonutData] = useState([]);
  // const [hopFilter, setHopFilter] = useState(0);

  // useEffect(() => {
  //   getCloudRiskData(hopFilter / 10);
  // }, [hopFilter]);

  useEffect(() => {
    if (isContainsValue(impactedDataStores)) {
      setDataStoreCount(impactedDataStores?.datastores);
    }
  }, [impactedDataStores]);

  useEffect(() => {
    getCloudRiskData();
  }, []);

  const navigateToCloudRisk = (title, path) => {
    sessionStorage.setItem("source_category", title);
    history.push(CLOUD_ENV + path);
  };

  const sliderLabels = [
    {
      value: 0,
      label: "Default",
    },
    {
      value: 10,
      label: "1",
    },
    {
      value: 20,
      label: "2",
    },
    {
      value: 30,
      label: "3",
    },
    {
      value: 40,
      label: "4",
    },
    {
      value: 50,
      label: "5+",
    },
  ];

  useEffect(() => {
    if (cloudRiskData?.length > 0) {
      setDonutData(
        cloudRiskData?.map(
          (
            {
              color,
              issueCount,
              dsCount,
              title,
              cloudEnvClass,
            }: {
              color: string;
              issueCount: number;
              dsCount: number;
              title: string;
              cloudEnvClass: string;
            },
            index: number
          ) => ({
            value: issueCount,
            color: cloudRiskDonutChartTheme[index]?.color,
            title,
            cloudEnvClass,
            dsCount,
          })
        )
      );
      const totalValue = cloudRiskData?.reduce(
        (
          acc: number,
          curr: { color: string; issueCount: number; title: string }
        ) => acc + curr.issueCount,
        0
      );

      setCloudRisk({
        totalValue,
        defaultColor: theme.palette.surface20.main,
        innerRadius: 115,
        outerRadius: 130,
        donutHeight: 90,
        donutWidth: 90,
        boxSize: 300,
      });
      setFirstRender(true);
    }
  }, [cloudRiskData]);

  const theme = useTheme();
  const styles = {
    flexAlignCenter: {
      display: "flex",
      alignItems: "center",
    },
    cloudRiskContainer: {
      width: "100%",
      display: "flex",
      height: "100%",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
    contentContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      gap: theme.spacing(5),
      paddingRight: theme.spacing(1),
    },
    cardHeader: {
      width: "100%",
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    moreDetailsButton: {
      display: "flex",
      marginLeft: "auto",
    },
    donutContainer: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(2.5),
      justifyContent: "space-between",
    },
    donutContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
    },
    donut: {
      width: "30%",
      display: "flex",
      justifyContent: "center",
    },
    donutDataContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      justifyContent: "center",
      width: "70%",
      overflow: "hidden",
    },
    donutDataRow: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    dataRiskFromCloudChartLegendLabel: {
      maxWidth: "144px",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    rowBadgeContainer: {
      gap: theme.spacing(0.5),
    },
    rowBadge: (color: string) => ({
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: "50%",
      backgroundColor: color,
    }),
    badgeValue: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    filterText: {
      fontWeight: theme.typography.fontWeightLight,
    },
    filterBarContainer: {
      visibility: "hidden",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    filterTextIcon: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    tooltipContent: {
      display: "flex",
      height: "100%",
    },
    infoIcon: {
      color: theme.palette.surface40.main,
      height: "16px",
      width: "16px",
    },
    donutInnerText: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      width: "66%",
      marginLeft: "auto",
    },
    totalValueText: { fontFamily: "Manrope", lineHeight: "100%" },
    totalIssuesText: {
      letterSpacing: "1.5px",
    },
  };

  return (
    <Box sx={styles.cloudRiskContainer}>
      {isFetchingCloudRiskData && !firstRender ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ ...styles.cardHeader, ...styles.flexAlignCenter }}>
            <Typography
              color={theme.palette.surface80.main}
              sx={styles.cardTitle}
            >
              Data Risk From Cloud
            </Typography>
            <TextButton
              hideText={true}
              endIcon={<KeyboardArrowRightIcon />}
              sx={styles.moreDetailsButton}
              link="vulnerabilities/cloud-env/all"
            >
              View All
            </TextButton>
          </Box>
          <Box sx={styles.contentContainer}>
            <Box sx={styles.donutContent}>
              <Box sx={styles.donutInnerText}>
                <Typography
                  variant="tooltip"
                  color={theme.palette.surface40.main}
                  sx={styles.totalIssuesText}
                >
                  TOTAL ISSUES
                </Typography>
                <Typography
                  variant="h4"
                  color={theme.palette.surface80.main}
                  sx={styles.totalValueText}
                >
                  {getNumberAbbreviation(cloudRisk?.totalValue)}
                </Typography>
              </Box>
              <Box sx={styles.donutContainer}>
                <Box sx={styles.donut}>
                  <DonutChart
                    donutData={donutData}
                    totalValue={cloudRisk?.totalValue}
                    defaultColor={cloudRisk?.defaultColor}
                    variant="MultiColor"
                    innerRadius={cloudRisk?.innerRadius}
                    outerRadius={cloudRisk?.outerRadius}
                    boxSize={cloudRisk?.boxSize}
                    donutHeight={cloudRisk?.donutHeight}
                    donutWidth={cloudRisk?.donutWidth}
                    classNameForGraph="cloudRiskDonut"
                  />
                </Box>
                <Box sx={styles.donutDataContainer}>
                  {donutData.map(
                    (
                      { color, value, title, cloudEnvClass, dsCount },
                      index
                    ) => {
                      const percentValue =
                        cloudRisk?.totalValue !== 0 && value !== 0
                          ? Math.round(
                              (value / cloudRisk?.totalValue) * 100 * 100
                            ) / 100
                          : 0;
                      return (
                        <Box
                          sx={styles.donutDataRow}
                          key={index}
                          onClick={() =>
                            navigateToCloudRisk(title, cloudEnvClass)
                          }
                        >
                          <Box
                            sx={{
                              ...styles.rowBadgeContainer,
                              ...styles.flexAlignCenter,
                            }}
                          >
                            <Box sx={styles.rowBadge(color)}></Box>
                            <Tooltip
                              title={`${title} - ${value}  (${percentValue}%)`}
                            >
                              <div style={styles.tooltipContent}>
                                <Typography
                                  variant="caption"
                                  color={theme.palette.surface60.main}
                                  sx={styles.dataRiskFromCloudChartLegendLabel}
                                >
                                  {getFormattedTitle(title)}
                                </Typography>
                              </div>
                            </Tooltip>
                          </Box>
                          <Box sx={styles.badgeValue}>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface80.main}
                            >
                              {getNumberAbbreviation(value)}
                            </Typography>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              ({percentValue}%)
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={styles.filterBarContainer}>
              <Box sx={styles.filterTextIcon}>
                <Typography
                  variant="caption"
                  color={theme.palette.surface80.main}
                  sx={styles.filterText}
                >
                  Filter By Number of Hops
                </Typography>
                <InfoOutlinedIcon sx={styles.infoIcon} />
              </Box>
              <Slider
                defaultValue={0}
                step={10}
                min={0}
                max={50}
                marks={sliderLabels}
                // onChange={(event: Event, value: number | number[]) => setHopFilter(value as number)}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CloudRiskCard;
